import { createTheme, experimental_sx as sx } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#00540A",
      transparent: "rgba(0, 128, 192, 0.5)",
      contrastText: "#F5F5F5",
    },
    secondary: {
      main: "#4AB362",
      contrastText: "#00540A"
    },
    background: {
      main: "#F5F5F5",
      contrastText: "#00540A"
    },
    danger: {
      main: "#DB3A34",
      contrastText: "#F0F3F5"
    },
    success: {
      main: "#018236",
      contrastText: "#F0F3F5"
    },
    text: {
      primary: "#000000",
      secondary: "#00540A",
      accent: "#F5F5F5",
    }
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: sx({
          '&.Mui-selected': {
            backgroundColor: "primary.main",
            color: "primary.contrastText",
            '&:hover': {
              backgroundColor: "primary.main",
              color: "primary.contrastText",
            },
            '&child': {
              color: "primary.contrastText",
            }
          },
          '&:hover': {
            backgroundColor: "primary.main",
            color: "primary.contrastText",
          }
        })
      }
    },
    MuiFormLabel:{
      styleOverrides: {
        root: sx({
          color: "primary.main",
        }),
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'unset',
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            fontSize: 20,
          },
        }
      }
    },
  }
});

export default theme;