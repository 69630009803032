import { Box, Container } from "@mui/material";
// import { useState } from "react";
// import TabPanel from "../../components/CustomTabPanel";
import Table from "../../components/pm-schedule/Table";
// import theme from "../../components/mui-theme";

export default function MasterIcae() {

  return (
    <Container>
      <h1>PM Schedule</h1>
      <Box xs={12}>
          <Table />
      </Box>  
    </Container>
  );
}
