import { Box, Container, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import TabPanel from "../../../components/CustomTabPanel";
import Table from "../../../components/master-list/icae/IcaeTable";
import theme from "../../../components/mui-theme";

export default function MasterIcae() {
  const [value, setValue] = useState(0);

  const tabs = [
    {
      label: "Motor",
      url: "motor",
      header: ["Description", "Process Section"],
      data: ["description", "processSection"],
    },
    {
      label: "Motor SLI",
      url: "motor-sli",
      header: ["Description", "Process Section"],
      data: ["description", "processSection"],
    },
    {
      label: "Motor C2",
      url: "motor-c2",
      header: ["Description", "Process Section"],
      data: ["description", "processSection"],
    },
    {
      label: "Motor Betaine",
      url: "motor-betaine",
      header: ["Description", "ISBL OSBL", "Type of Starter", "Merk", "Serial Number"],
      data: ["description", "isblOsbl", "typeOfStarter", "merk", "serialNumber"],
    },
    {
      label: "Motor Pump Pilot Plant",
      url: "motor-pump-pilot-plant",
      header: ["Description", "ISBL OSBL", "Type of Starter", "Merk", "Serial Number"],
      data: ["description", "isblOsbl", "typeOfStarter", "merk", "serialNumber"],
    },
    {
      label: "PSV",
      url: "psv",
      header: [
        "Description",

        "Process Section",
        "Service",
        "Location",
        "Elevation",
        "Merk",
        "Model Number",
        "I/O Type",
        "Alarm Display",
        "External Power Supply",
      ],
      data: ["description", "processSection", "service", "location", "elevation", "merk", "modelNumber", "ioType", "alarmDisplay", "externalPowerSupply"],
    },
    {
      label: "PSV Betaine",
      url: "psv-betaine",
      header: ["Brand", "ISBL OSBL", "Model Number", "Location"],
      data: ["brand", "isblOsbl", "modelNumber", "location"],
    },
    {
      label: "Instrument SLI",
      url: "instrument-sli",
      header: [
        "Description",

        "Process Section",
        "Service",
        "Location",
        "Elevation",
        "Merk",
        "Model Number",
        "I/O Type",
        "Alarm Display",
        "External Power Supply",
      ],
      data: ["description", "processSection", "service", "location", "elevation", "merk", "modelNumber", "ioType", "alarmDisplay", "externalPowerSupply"],
    },
    {
      label: "Instrument",
      url: "instrument",
      header: [
        "Description",

        "Process Section",
        "Service",
        "Location",
        "Elevation",
        "Merk",
        "Model Number",
        "I/O Type",
        "Alarm Display",
        "External Power Supply",
      ],
      data: ["description", "processSection", "service", "location", "elevation", "merk", "modelNumber", "ioType", "alarmDisplay", "externalPowerSupply"],
    },
    {
      label: "Instrument C2",
      url: "instrument-c2",
      header: ["Service", "PID", "Section", "Model"],
      data: ["service", "pid", "section", "model"],
    },
    {
      label: "Instrument Betaine",
      url: "instrument-betaine",
      header: ["Brand", "ISBL OSBL", "Model Number Sensor", "Model Number Transmitter", "Location", "Instrument Range"],
      data: ["brand", "isblOsbl", "modelNumberSensor", "modelNumberTransmitter", "location", "instrumentRange"],
    },
    {
      label: "Instrument PP",
      url: "instrument-pp",
      header: [
        "Description",
        "Process Section",
        "Service",
        "Location",
        "Elevation",
        "Merk",
        "Model Number",
        "I/O Type",
        "Alarm Display",
        "External Power Supply",
      ],
      data: ["description", "processSection", "service", "location", "elevation", "merk", "modelNumber", "ioType", "alarmDisplay", "externalPowerSupply"],
    },
    {
      label: "WIT C2",
      url: "wit-c2",
      header: ["Service", "PID", "Model"],
      data: ["service", "pid", "model"],
    },
    {
      label: "On Off Valve",
      url: "on-off-valve",
      header: [
        "Description",
        "Process Section",
        "Service",
        "Location",
        "Elevation",
        "Merk",
        "Model Number",
        "I/O Type",
        "Alarm Display",
        "External Power Supply",
      ],
      data: ["description", "processSection", "service", "location", "elevation", "merk", "modelNumber", "ioType", "alarmDisplay", "externalPowerSupply"],
    },
    {
      label: "On Off Valve C2",
      url: "on-off-valve-c2",
      header: ["Service", "PID", "Model"],
      data: ["service", "pid", "model"],
    },
    {
      label: "On Off Valve Betaine",
      url: "on-off-valve-betaine",
      header: [
        "Brand",
        "ISBL OSBL",
        "Model Number Valve Body",
        "Model Number Actuator",
        "Model Number Solenoid",
        "Model Number Switches",
        "Model Number Air Set",
        "Location",
      ],
      data: ["brand", "isblOsbl", "modelNumberValveBody", "modelNumberActuator", "modelNumberSolenoid", "modelNumberSwitches", "modelNumberAirSet", "location"],
    },
    {
      label: "On Off Valve SLI",
      url: "on-off-valve-sli",
      header: [
        "Description",
        "Process Section",
        "Service",
        "Location",
        "Elevation",
        "Merk",
        "Model Number",
        "I/O Type",
        "Alarm Display",
        "External Power Supply",
      ],
      data: ["description", "processSection", "service", "location", "elevation", "merk", "modelNumber", "ioType", "alarmDisplay", "externalPowerSupply"],
    },
    {
      label: "Limit Switch",
      url: "limit-switch",
      header: [
        "Description",
        "Process Section",
        "Service",
        "Location",
        "Elevation",
        "Merk",
        "Model Number",
        "I/O Type",
        "Alarm Display",
        "External Power Supply",
      ],
      data: ["description", "processSection", "service", "location", "elevation", "merk", "modelNumber", "ioType", "alarmDisplay", "externalPowerSupply"],
    },
    {
      label: "Limit Switch C2",
      url: "limit-switch-c2",
      header: ["Service", "PID", "Model"],
      data: ["service", "pid", "model"],
    },
    {
      label: "Control Valve",
      url: "control-valve",
      header: [
        "Description",
        "Process Section",
        "Service",
        "Location",
        "Elevation",
        "Merk",
        "Model Number",
        "I/O Type",
        "Alarm Display",
        "External Power Supply",
      ],
      data: ["description", "processSection", "service", "location", "elevation", "merk", "modelNumber", "ioType", "alarmDisplay", "externalPowerSupply"],
    },
    {
      label: "Control Valve C2",
      url: "control-valve-c2",
      header: ["Service", "PID", "Section", "Instrument Type", "Model"],
      data: ["service", "pid", "section", "instrumentType", "model"],
    },
    {
      label: "Control Valve SLI",
      url: "control-valve-sli",
      header: [
        "Description",
        "Process Section",
        "Service",
        "Location",
        "Elevation",
        "Merk",
        "Model Number",
        "I/O Type",
        "Alarm Display",
        "External Power Supply",
      ],
      data: ["description", "processSection", "service", "location", "elevation", "merk", "modelNumber", "ioType", "alarmDisplay", "externalPowerSupply"],
    },
    {
      label: "Control Valve Betaine",
      url: "control-valve-betaine",
      header: ["Brand", "ISBL OSBL", "Model Number Valve Body", "Model Number Actuator", "Model Number Positioner", "Location"],
      data: ["brand", "isblOsbl", "modelNumberValveBody", "modelNumberActuator", "modelNumberPositioner", "location"],
    },
    {
      label: "CV & AV Pilot Plant",
      url: "cv-av-pilot-plant",
      header: [
        "Description",
        "Process Section",
        "Service",
        "Location",
        "Elevation",
        "Merk",
        "Model Number",
        "I/O Type",
        "Alarm Display",
        "External Power Supply",
      ],
      data: ["description", "processSection", "service", "location", "elevation", "merk", "modelNumber", "ioType", "alarmDisplay", "externalPowerSupply"],
    },
    {
      label: "TOH",
      url: "toh",
      header: [
        "Description",
        "Process Section",
        "Service",
        "Location",
        "Elevation",
        "Merk",
        "Model Number",
        "I/O Type",
        "Alarm Display",
        "External Power Supply",
      ],
      data: ["description", "processSection", "service", "location", "elevation", "merk", "modelNumber", "ioType", "alarmDisplay", "externalPowerSupply"],
    },
    {
      label: "Panel",
      url: "panel",
      header: ["Brand", "Location"],
      data: ["brand", "location"],
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Container>
      <h1>Master List & PIC Plant ICAE</h1>
      <Box xs={12}>
        <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example">
          {tabs.map((tab, index) => (
            <Tab label={tab.label} key={index} />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab, index) => (
        <TabPanel key={index} value={value} index={index} style={{ backgroundColor: theme.palette.primary.light }}>
          <Table url={tab.url} header={tab.header} dataHeader={tab.data} />
        </TabPanel>
      ))}
    </Container>
  );
}
