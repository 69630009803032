import { useTranslation } from "react-i18next";

import { Table, TableBody, TableCell, TableHead, TableRow, Checkbox, FormControlLabel } from '@mui/material';

export default function DenseTable({ menu, access, onChange }) {
  const { t } = useTranslation();

  const handleChange = (e, menuId, access) => {
    onChange(e.target.checked, menuId, access);
  }

  return (
    <div>
      <Table sx={{ minWidth: 600 }} size="large" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Menu</TableCell>
            <TableCell align="center">Read</TableCell>
            <TableCell align="center">Export</TableCell>
            <TableCell align="center">Create</TableCell>
            <TableCell align="center">Update</TableCell>
            <TableCell align="center">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {menu.length === 0 && (
            <TableRow>
              <TableCell colSpan={3} align="center">
                {t('component.text.noData')}
              </TableCell>
            </TableRow>
          )}
          {menu && menu.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="center">
                <FormControlLabel control={<Checkbox defaultChecked={access.find(access => access.menuId === row.id)?.isRead ? true : false} onChange={(e) => handleChange(e, row.id, 'isRead')} />} label="isRead" />
              </TableCell>
              <TableCell align="center">
                <FormControlLabel control={<Checkbox defaultChecked={access.find(access => access.menuId === row.id)?.isExport ? true : false} onChange={(e) => handleChange(e, row.id, 'isExport')} />} label="isExport" />
              </TableCell>
              <TableCell align="center">
                <FormControlLabel control={<Checkbox defaultChecked={access.find(access => access.menuId === row.id)?.isCreate ? true : false} onChange={(e) => handleChange(e, row.id, 'isCreate')} />} label="isCreate" />
              </TableCell>
              <TableCell align="center">
                <FormControlLabel control={<Checkbox defaultChecked={access.find(access => access.menuId === row.id)?.isUpdate ? true : false} onChange={(e) => handleChange(e, row.id, 'isUpdate')} />} label="isUpdate" />
              </TableCell>
              <TableCell align="center">
                <FormControlLabel control={<Checkbox defaultChecked={access.find(access => access.menuId === row.id)?.isDelete ? true : false} onChange={(e) => handleChange(e, row.id, 'isDelete')} />} label="isDelete" />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
