import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";

import SubmitForm from "../../components/SubmitForm";
import Appreciate from "../../components/Appreciate";
import { toast } from "react-hot-toast";

export default function PlannedTask() {
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [frequencies, setFrequencies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [openAppreciate, setOpenAppreciate] = useState(false);

  const [area, setArea] = useState("");
  const [frequency, setFrequency] = useState("");
  const [file, setFile] = useState(null);
  const [notes, setNotes] = useState("");

  const [comment, setComment] = useState([]);
  const [answer, setAnswer] = useState([]);
  const [attachment, setAttachment] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.get(`/planned-task/${id}`);
    setData(res.data.data);
    setLoading(false);
  };

  const getFrequencies = async () => {
    const res = await axios.get("/master-form-approval/frequency");
    setFrequencies(res.data.data.results);
  };

  const handleUpload = (id) => async (e) => {
    const { files } = e.target;

    const upload = await axios.post(
      "/media",
      {
        file: files[0],
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    const fileName = upload.data.data.url;
    const newAttachment = [...attachment];
    newAttachment[id] = fileName;
    setAttachment(newAttachment);
  };

  const handleUploadFile = async (e) => {
    const { files } = e.target;

    const upload = await axios.post(
      "/media",
      {
        file: files[0],
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    const fileName = upload.data.data.url;
    setFile(fileName);
  };

  const handleChange = (id) => (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "comment":
        const newComment = [...comment];
        newComment[id] = value;
        setComment(newComment);
        break;
      case "answer":
        const newAnswer = [...answer];
        newAnswer[id] = value;
        setAnswer(newAnswer);
        break;
      case "attachment":
        handleUpload(id)(e);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const detail = answer
      .map((item, index) => ({
        formDetailId: index,
        answer: item,
        notes: comment[index] ?? null,
        image: attachment[index] ?? null,
      }))
      .filter((item) => item.answer);
    const data = {
      plannedTaskId: id,
      area: area || null,
      frequencyId: frequency || null,
      notes: notes || null,
      attachment: file || null,
      detail,
    };
    try {
      const res = await axios.post(`/planned-task`, data);
      res.data.message.reverse().forEach((message) => {
        toast.success(message);
      });
      setOpen(false);
      setOpenAppreciate(true);
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
      setOpen(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAppreciate = () => {
    setOpenAppreciate(false);
    navigate("/planned-task");
  };

  useEffect(() => {
    getData();
    getFrequencies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <SubmitForm
        handleSubmit={handleSubmit}
        open={open}
        onClose={handleClose}
        loading={loading}
      />
      <Appreciate open={openAppreciate} onClose={handleCloseAppreciate} />

      <Paper
        xs={12}
        sx={{
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          padding: 2,
          borderRadius: 2,
          marginBottom: 2,
        }}
      >
        <Typography variant="h4">{data?.form?.name}</Typography>
        <Typography>{data.tag_number.tagNumber}</Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              autoFocus
              margin="dense"
              name="tagNumber"
              label="No. Equipment"
              type="text"
              fullWidth
              value={data.tag_number.tagNumber}
              disabled
              // onChange={(e) => setOrder(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              autoFocus
              margin="dense"
              name="tagNumber"
              label={t("component.text.description")}
              type="text"
              fullWidth
              value={data.category.name}
              disabled
              // onChange={(e) => setOrder(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              autoFocus
              margin="dense"
              name="tagNumber"
              label={t("component.text.area")}
              type="text"
              fullWidth
              value={area}
              onChange={(e) => setArea(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              autoFocus
              margin="dense"
              name="supervisor"
              label={t("component.text.supervisor")}
              type="text"
              fullWidth
              disabled
              value={JSON.parse(localStorage.getItem("user")).name}
              // onChange={(e) => setArea(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth margin="dense">
              <InputLabel id="menu">{t("component.text.frequency")}</InputLabel>
              <Select
                labelId="menu"
                name="menuId"
                value={frequency}
                label="Menu"
                onChange={(e) => setFrequency(e.target.value)}
              >
                {frequencies?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
      {data.form.detail.map((item) => (
        <Paper
          key={item.id}
          xs={12}
          sx={{
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            padding: 2,
            borderRadius: 2,
            marginBottom: 2,
          }}
        >
          <Typography variant="h6" align="start">
            {localStorage.getItem("language") === "en"
              ? item.activitiesEn
              : item.activitiesId}
          </Typography>

          <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={12} md={2}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="answer"
                  value={answer[`${item.id}`]}
                  onChange={handleChange(item.id)}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label={t("component.text.yes")}
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label={t("component.text.no")}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={10}>
              <TextField
                autoFocus
                margin="dense"
                name="comment"
                label={t("component.text.comment")}
                type="text"
                fullWidth
                value={comment[`${item.id}`]}
                onChange={handleChange(item.id)}
              />
            </Grid>
          </Grid>
        </Paper>
      ))}
      <Paper
        xs={12}
        sx={{
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          padding: 2,
          borderRadius: 2,
          marginBottom: 2,
        }}
      >
        <Typography variant="h6" align="start">
          {/* {localStorage.getItem("language") === "en"
            ? item.activitiesEn
            : item.activitiesId} */}
        </Typography>

        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={12} md={10}>
            <TextField
              autoFocus
              margin="dense"
              name="comment"
              label={t("component.text.notes")}
              type="text"
              fullWidth
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Button variant="contained" component="label" fullWidth>
              {t("component.button.upload")}
              <input
                type="file"
                hidden
                accept="image/*"
                name="file"
                onChange={handleUploadFile}
              />
            </Button>
          </Grid>
          <Grid item xs={12}>
            {file ? (
              <img
                key={file}
                src={`${process.env.REACT_APP_API_URL}${file}?w=164&h=164&fit=crop&auto=format`}
                srcSet={`${process.env.REACT_APP_API_URL}${file}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                alt={file}
                style={{
                  maxHeight: 300,
                }}
                loading="lazy"
              />
            ) : null}
          </Grid>
        </Grid>
      </Paper>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleOpen}
          >
            {t("component.button.submit")}
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Link to="/planned-task">
            <Button variant="contained" color="background" fullWidth>
              {t("component.button.cancel")}
            </Button>
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}
