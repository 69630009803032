import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export default function UpdateModal({ open, onClose, id }) {
  const axios = useAxiosPrivate();
  const { t } = useTranslation();

  // State for form fields
  const [order, setOrder] = useState("");
  const [createdOn, setCreatedOn] = useState("");
  const [basicStartDate, setBasicStartDate] = useState("");
  const [basicFinalDate, setBasicFinalDate] = useState("");
  const [finishDate, setFinishDate] = useState("");
  const [functionalLocation, setFunctionalLocation] = useState("");
  const [asset, setAsset] = useState("");
  const [description, setDescription] = useState("");
  const [lifecycleState, setLifecycleState] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`/pm-schedule/${id}`);
        const data = res.data.data;

        setOrder(data.order);
        setCreatedOn(data.createdOn);
        setBasicStartDate(data.basicStartDate);
        setBasicFinalDate(data.basicFinalDate);
        setFinishDate(data.finishDate);
        setFunctionalLocation(data.functionalLocation);
        setAsset(data.asset);
        setDescription(data.description);
        setLifecycleState(data.lifecycleState);
      } catch (err) {
        err.response.data?.message.reverse().forEach((message) => {
          toast.error(message);
        });
      }
    };

    if (id) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      order,
      createdOn,
      basicStartDate,
      basicFinalDate,
      finishDate,
      functionalLocation,
      asset,
      description,
      lifecycleState,
    };

    console.log(data);

    try {
      const res = await axios.put(`/pm-schedule/${id}`, data);
      res.data.message.reverse().forEach((message) => {
        toast.success(message);
      });
      onClose();
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {t("component.title.editPMSchedule")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("component.title.editPMScheduleDesc")}
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            margin="dense"
            name="order"
            label="Order"
            type="text"
            fullWidth
            value={order}
            onChange={(e) => setOrder(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            name="createdOn"
            label="Created On"
            type="text"
            fullWidth
            value={createdOn}
            onChange={(e) => setCreatedOn(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            name="basicStartDate"
            label="Basic Start Date"
            type="text"
            fullWidth
            value={basicStartDate}
            onChange={(e) => setBasicStartDate(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            name="basicFinalDate"
            label="Basic Final Date"
            type="text"
            fullWidth
            value={basicFinalDate}
            onChange={(e) => setBasicFinalDate(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            name="finishDate"
            label="Finish Date"
            type="text"
            fullWidth
            value={finishDate}
            onChange={(e) => setFinishDate(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            name="functionalLocation"
            label="Functional Location"
            type="text"
            fullWidth
            value={functionalLocation}
            onChange={(e) => setFunctionalLocation(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            name="asset"
            label="Asset"
            type="text"
            fullWidth
            value={asset}
            onChange={(e) => setAsset(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            name="description"
            label="Description"
            type="text"
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            name="lifecycleState"
            label="Lifecycle State"
            type="text"
            fullWidth
            value={lifecycleState}
            onChange={(e) => setLifecycleState(e.target.value)}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("component.button.cancel")}
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          {t("component.button.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
