import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import MonthYear from "../../components/filters/MonthYear";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
} from "@mui/material";
import {
  Chart as Chartjs,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

Chartjs.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const options = {
  indexAxis: "y",
  scales: {
    y: {
      type: "category", // Using "category" scale for y-axis
    },
  },
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "right",
    },
    // title: {
    //   display: true,
    //   text: "Chart.js Horizontal Bar Chart",
    // },
  },
};

export default function Dashboard() {
  const axios = useAxiosPrivate();

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [data, setData] = useState(null);
  // eslint-disable-next-line
  const [month, setMonth] = useState(new Date().getMonth());
  // eslint-disable-next-line
  const [year, setYear] = useState(new Date().getFullYear());

  const getUserByRole = async () => {
    try {
      const isLead = JSON.parse(localStorage.getItem("user")).roles.find(
        (role) => role.roleId === "LEAD" || role.roleId === "SU"
      );
      if (isLead) {
        const response = await axios.get(`/dashboard/user/EMP`);
        setUsers(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async () => {
    try {
      const params = `month=${month}&year=${year}`;
      const response = selectedFilter
        ? await axios.get(`/dashboard?type=${selectedFilter}&${params}`)
        : await axios.get(
            `/dashboard/${
              selectedUser?.id ?? JSON.parse(localStorage.getItem("user")).id
            }?${params}`
          );
      setData({
        datasets: [
          {
            label: "",
            data: [
              { y: "Actual", x: response.data.data.actual },
              { y: "Planned", x: response.data.data.planned },
            ],
            backgroundColor: ["#00540A", "#4AB362"],
          },
        ],
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserByRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser, month, year, selectedFilter]);

  return (
    <div>
      <h1>Dashboard</h1>
      <Paper
        xs={12}
        sx={{
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          // padding: 2,
          borderRadius: 2,
          marginBottom: 2,
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            md={users.length > 0 && 4}
            justifyContent={"start"}
          >
            <List>
              {users && (
                <>
                  <ListItem disablePadding>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: "center",
                        px: 2.5,
                      }}
                      selected={selectedFilter === "icae"}
                      onClick={() => {
                        setSelectedFilter("icae");
                        setSelectedUser(null);
                      }}
                    >
                      <ListItemText
                        primary={"Plant ICAE"}
                        sx={{ display: "block" }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding sx={{ marginBottom: 4 }}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: "center",
                        px: 2.5,
                      }}
                      selected={selectedFilter === "mekanikal"}
                      onClick={() => {
                        setSelectedFilter("mekanikal");
                        setSelectedUser(null);
                      }}
                    >
                      <ListItemText
                        primary={"Plant Mekanikal"}
                        sx={{ display: "block" }}
                      />
                    </ListItemButton>
                  </ListItem>
                  {users.map((user) => (
                    <ListItem key={user.id} disablePadding>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: "center",
                          px: 2.5,
                        }}
                        selected={user.id === selectedUser?.id}
                        onClick={() => {
                          setSelectedUser(user);
                          setSelectedFilter("");
                        }}
                      >
                        <ListItemText
                          primary={user.name}
                          sx={{ display: "block" }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </>
              )}
            </List>
          </Grid>
          <Grid item xs={12} md={users.length > 0 ? 8 : 12} spacing={2}>
            <Box sx={{ m: 5 }}>
              <MonthYear
                month={month}
                setMonth={setMonth}
                year={year}
                setYear={setYear}
              />
            </Box>

            {data && <Bar options={options} data={data} />}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
