import { Card, Container } from "@mui/material";
import { useEffect, useState } from "react";

import UserTable from "../../components/admin/user-management/UserTable";
import Loading from "../../components/Loading";

export default function UserManagement() {
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const titleName = "User Management";
    setTitle(titleName);
    document.title = `${titleName} - ${process.env.REACT_APP_NAME}`;
    setLoading(false);

    // eslint-disable-next-line
  }, []);

  if (loading) return <Loading />;

  return (
    <Container>
      <h1>{title}</h1>
      <Card>
        <UserTable />
      </Card>
    </Container>
  );
}
