import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import {
  alpha,
  Button,
  Grid,
  InputBase,
  styled,
  TablePagination,
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Box,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import UpdateModal from "./UpdateModal";
import ConfirmDelete from "../ConfirmDelete";
import { Link } from "react-router-dom";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.primary.main, 0.08),
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function IcaeTable({ url, header, dataHeader }) {
  const axios = useAxiosPrivate();
  const { t } = useTranslation();
  const [data, setData] = useState(new Set());
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [openUpd, setOpenUpd] = useState(false);
  const [total, setTotal] = useState(0);
  const [idUpd, setIdUpd] = useState(null);
  const [search, setSearch] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [idDelete, setIdDelete] = useState(null);

  const fetchData = async () => {
    try {
      const result = await axios.get(
        `/pm-schedule?page=${page}&limit=${rowsPerPage}&search=${search}`
      );
      setData((prev) => new Set(result.data.data.results));
      setTotal(result.data.data.total);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [page, rowsPerPage, search]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setPage(0);
  };

  const handleCloseUpd = () => {
    setOpenUpd(false);
    setIdUpd(null);
    fetchData();
  };

  const handleOpenDelete = (id) => {
    setIdDelete(id);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setIdDelete(null);
  };

  const handleDelete = async (id) => {
    try {
      const res = await axios.delete(`/pm-schedule/${id}`);
      res.data.message.reverse().forEach((message) => {
        toast.success(message);
      });
      fetchData();
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
    setOpenDelete(false);
    setIdDelete(null);
  };

  const handleUpdate = (id) => {
    setIdUpd(id);
    setOpenUpd(true);
  };

  return (
    <Box>
      <ConfirmDelete
        open={openDelete}
        onClose={handleCloseDelete}
        id={idDelete}
        handleDelete={handleDelete}
      />
      <UpdateModal
        open={openUpd}
        onClose={handleCloseUpd}
        id={idUpd}
        header={header}
        dataHeader={dataHeader}
        url={url}
      />
      <TableContainer component={Paper}>
        <Grid container justifyContent={"space-between"} sx={{ padding: 2 }}>
          <Grid item>
            <Search>
              <SearchIconWrapper>
                <FontAwesomeIcon icon={"fa-solid fa-magnifying-glass"} />
              </SearchIconWrapper>
              <StyledInputBase
                value={search}
                onChange={handleSearch}
                placeholder={`${t("component.text.search")}...`}
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              {/* <Grid item>
                <Button color="primary" variant="contained">
                  {t("component.button.create")}
                </Button>
              </Grid> */}
              <Grid item>
                <Link to={`/pm-schedule/import`}>
                  <Button color="primary" variant="contained">
                    {t("component.button.import")}
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* <Box sx={{ overflow: "auto" }}> */}
        {/* <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}> */}
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Order</TableCell>
              <TableCell align="center">Created On</TableCell>
              <TableCell align="center">Basic Start Date</TableCell>
              <TableCell align="center">Basic Final Date</TableCell>
              <TableCell align="center">Finish Date</TableCell>
              <TableCell align="center">Functional Location</TableCell>
              <TableCell align="center">Asset</TableCell>
              <TableCell align="center">Description</TableCell>
              <TableCell align="center">PIC</TableCell>
              <TableCell align="center">Lifecycle State</TableCell>
              <TableCell align="center">{t("component.text.action")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading && data.size === 0 && (
              <TableRow>
                <TableCell colSpan={11} align="center">
                  {t("component.text.noData")}
                </TableCell>
              </TableRow>
            )}
            {!loading &&
              data.size > 0 &&
              Array.from(data).map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.order}</TableCell>
                  <TableCell align="center">
                    {row.createdOn &&
                      new Date(row.createdOn * 1000).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="center">
                    {row.basicStartDate &&
                      new Date(row.basicStartDate * 1000).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="center">
                    {row.basicFinalDate &&
                      new Date(row.basicFinalDate * 1000).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="center">
                    {row.finishDate &&
                      new Date(row.finishDate * 1000).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="center">{row.functionalLocation}</TableCell>
                  <TableCell align="center">{row.asset}</TableCell>
                  <TableCell align="center">{row.description}</TableCell>
                  <TableCell align="center">{row.pic?.name}</TableCell>
                  <TableCell align="center">{row.lifecycleStatus}</TableCell>
                  <TableCell align="center">
                    <Grid container spacing={1}>
                      <Grid item xs>
                        <Button
                          size="small"
                          variant="contained"
                          color="success"
                          onClick={() => handleUpdate(row.id)}
                        >
                          {t("component.button.update")}
                        </Button>
                      </Grid>
                      <Grid item xs>
                        <Button
                          size="small"
                          variant="contained"
                          color="danger"
                          onClick={() => handleOpenDelete(row.id)}
                        >
                          {t("component.button.delete")}
                        </Button>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {/* </Box> */}
        {/* </Box> */}
      </TableContainer>
    </Box>
  );
}
