import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Box, Button, Grid, Paper, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { jsPDF } from "jspdf";
import { renderToString } from "react-dom/server";
import logo from "../../assets/img/logo.png";

export default function PlannedTask() {
  const doc = new jsPDF({
    orientation: "p",
    unit: "mm",
    format: "a4",
  });
  const axios = useAxiosPrivate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0);

  const getData = async () => {
    setLoading(true);
    const res = await axios.get(`/submission-status/${id}`);
    setData(res.data.data);
    const step = res.data.data.approval.findIndex((apr) => apr.isApproved === 0);
    setActiveStep(step);
    setLoading(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const pdf = (
    <div
      style={{
        fontFamily: "Helvetica",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
          border: "1px solid black",
          width: "50mm",
          height: "7mm",
        }}
      >
        <img
          src={logo}
          alt=""
          style={{
            width: "20px",
            margin: "0 10px 0 0",
          }}
        />
        <div>
          <p
            style={{
              fontSize: "5px",
              textAlign: "center",
              margin: "0 10px",
            }}
          >
            <b>PT UNILEVER OLEOCHEMICAL INDONESIA</b>
          </p>
          <p
            style={{
              fontSize: "4px",
              textAlign: "center",
            }}
          >
            <b>{data?.form?.name}</b>
          </p>
        </div>
      </div>
      <div
        style={{
          // display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "50mm",
        }}
      >
        <div
          style={{
            marginBottom: "0px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              No. Equipment
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              : {data?.tag_number?.tagNumber}
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              Waktu Penyimpanan Data
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              : {new Date(data?.answer?.createdAt * 1000).toLocaleString()}
            </p>
          </div>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "-10px",
            }}
          >
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "25mm",
              }}
            >
              <i>Equipment Nr</i>
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "25mm",
              }}
            >
              <i>Record Retention Time</i>
            </p>
          </div>
        </div>
        <div
          style={{
            marginTop: "-10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              Deskripsi
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              : {data?.category?.name}
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            ></p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            ></p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "-10px",
            }}
          >
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              <i>Description</i>
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            ></p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            ></p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            ></p>
          </div>
        </div>
        <div
          style={{
            marginTop: "-10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              Area
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              : {data?.answer?.area}
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            ></p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            ></p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "-10px",
            }}
          >
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              <i>Area</i>
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            ></p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            ></p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            ></p>
          </div>
        </div>
        <div
          style={{
            marginTop: "-10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              Pengawas
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              : {data?.user?.name}
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            ></p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            ></p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "-10px",
            }}
          >
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              <i>Supervisor</i>
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            ></p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            ></p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            ></p>
          </div>
        </div>
        <div
          style={{
            marginTop: "-10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              Pemeriksa (periode 6 Bln)
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              : {data.answer.frequencyId === 1 && data?.user?.name}
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              Tanggal (periode 6 Bln)
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              : {data.answer.frequencyId === 1 && new Date(data?.answer?.createdAt * 1000).toLocaleString()}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "-10px",
            }}
          >
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              <i>Inspector (periode 6 M)</i>
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            ></p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              <i>Date (periode 6 M)</i>
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            ></p>
          </div>
        </div>
        <div
          style={{
            marginTop: "-10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              Pemeriksa (periode 1Thn)
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              : {data.answer.frequencyId === 2 && data?.user?.name}
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              Tanggal (periode 1Thn)
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              : {data.answer.frequencyId === 2 && new Date(data?.answer?.createdAt * 1000).toLocaleString()}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "-10px",
            }}
          >
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              <i>Inspector (periode 1 Y)</i>
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            ></p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              <i>Date (periode 1 Y)</i>
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            ></p>
          </div>
        </div>
        <div
          style={{
            marginTop: "-10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              Pemeriksa(periode 6 Bln)
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              :
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              Tanggal (periode 6 Bln)
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              :
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "-10px",
            }}
          >
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              <i>Inspector (periode 6 M)</i>
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            ></p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              <i>Date (periode 6 M)</i>
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            ></p>
          </div>
        </div>
        <div
          style={{
            marginTop: "-10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              Pemeriksa(periode 2Thn)
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              : {data.answer.frequencyId === 3 && data?.user?.name}
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              Tanggal (periode 2Thn)
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              : {data.answer.frequencyId === 3 && new Date(data?.answer?.createdAt * 1000).toLocaleString()}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "-10px",
            }}
          >
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              <i>Inspector (periode 2 Y)</i>
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            ></p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            >
              <i>Date (periode 2 Y)</i>
            </p>
            <p
              style={{
                fontSize: "3px",
                lineHeight: "2.5",
                width: "12.5mm",
              }}
            ></p>
          </div>
        </div>
      </div>
      <div>
        <table
          style={{
            border: "0.1px solid black",
            borderCollapse: "collapse",
            width: "50mm",
            fontSize: "3px",
            lineHeight: "2",
            textAlign: "center",
          }}
        >
          <thead>
            <tr>
              <th
                rowspan="2"
                style={{
                  border: "0.1px solid black",
                }}
              >
                <p>No</p>
                <p>
                  <i>No</i>
                </p>
              </th>
              <th
                rowspan="2"
                style={{
                  border: "0.1px solid black",
                }}
              >
                <p>Deskripsi</p>
                <p>
                  <i>Description</i>
                </p>
              </th>
              <th
                colspan="4"
                style={{
                  border: "0.1px solid black",
                }}
              >
                <p>Frekuensi</p>
                <p>
                  <i>Frequency</i>
                </p>
              </th>
            </tr>
            <tr>
              <th
                style={{
                  border: "0.1px solid black",
                }}
              >
                6M
              </th>
              <th
                style={{
                  border: "0.1px solid black",
                }}
              >
                1Y
              </th>
              <th
                style={{
                  border: "0.1px solid black",
                }}
              >
                6M
              </th>
              <th
                style={{
                  border: "0.1px solid black",
                }}
              >
                2Y
              </th>
            </tr>
          </thead>
          <tbody>
            {data.form.detail.map((item, index) => (
              <tr>
                <td
                  style={{
                    border: "0.1px solid black",
                  }}
                >
                  {index + 1}
                </td>
                <td
                  style={{
                    border: "0.1px solid black",
                  }}
                >
                  <span style={{ fontSize: "3px", lineHeight: "70%" }}>{item.activitiesId}</span>
                  <br />
                  <span style={{ fontSize: "3px", lineHeight: "70%" }}>
                    <i>{item.activitiesEn}</i>
                  </span>
                </td>
                <td
                  style={{
                    border: "0.1px solid black",
                  }}
                >
                  {data.answer.frequencyId === 1 ? (data.answer.detail.find((answer) => answer.formDetailId === item.id).answer === 1 ? "Y" : "N") : ""}
                </td>
                <td
                  style={{
                    border: "0.1px solid black",
                  }}
                >
                  {data.answer.frequencyId === 2 ? (data.answer.detail.find((answer) => answer.formDetailId === item.id).answer === 1 ? "Y" : "N") : ""}
                </td>
                <td
                  style={{
                    border: "0.1px solid black",
                  }}
                >
                  {""}
                </td>
                <td
                  style={{
                    border: "0.1px solid black",
                  }}
                >
                  {data.answer.frequencyId === 3 ? (data.answer.detail.find((answer) => answer.formDetailId === item.id).answer === 1 ? "Y" : "N") : ""}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div
        style={{
          fontSize: "4px",
          width: "50mm",
        }}
      >
        <p>
          Catatan/
          <span>
            <i>Note</i>
          </span>{" "}
          :
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "12.5mm",
            }}
          >
            <p
              style={{
                marginTop: "-2px",
              }}
            >
              N: Normal
            </p>
            <p
              style={{
                marginTop: "-5px",
              }}
            >
              <i>N: Normal</i>
            </p>
            <p
              style={{
                marginTop: "-5px",
              }}
            >
              R: Pabrik Beroperasi
            </p>
            <p
              style={{
                marginTop: "-5px",
              }}
            >
              <i>R: Plant Run</i>
            </p>
          </div>
          <div
            style={{
              width: "12.5mm",
            }}
          >
            <p
              style={{
                marginTop: "-2px",
              }}
            >
              x: Tidak normal
            </p>
            <p
              style={{
                marginTop: "-5px",
              }}
            >
              <i>x: Abnormal</i>
            </p>
            <p
              style={{
                marginTop: "-5px",
              }}
            >
              S: Pabrik Berhenti
            </p>
            <p
              style={{
                marginTop: "-5px",
              }}
            >
              <i>S: Plant Stop</i>
            </p>
          </div>
          <div
            style={{
              width: "12.5mm",
            }}
          >
            <p
              style={{
                marginTop: "-2px",
              }}
            >
              Bln: Bulan
            </p>
            <p
              style={{
                marginTop: "-5px",
              }}
            >
              <i>M: Month</i>
            </p>
            <p
              style={{
                marginTop: "-5px",
              }}
            >
              Thn: Tahun
            </p>
            <p
              style={{
                marginTop: "-5px",
              }}
            >
              <i>Y: Year</i>
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          fontSize: "4px",
          width: "50mm",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "12.5mm",
            textAlign: "center",
            border: "0.1px solid black",
          }}
        >
          <p>Tanggal / Date :</p>
          <p
            style={{
              marginTop: -4,
            }}
          >
            {data.answer.frequencyId === 1 ? new Date(data?.answer?.createdAt * 1000).toLocaleString().split(",")[0] : "-"}
          </p>
          <>
            <p>Pemeriksaan Oleh :</p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              <i>Inspection By:</i>
            </p>

            <img
              style={{
                width: "8mm",
                height: "8mm",
              }}
              src={data.answer.frequencyId === 1 && `${process.env.REACT_APP_API_URL}${data.user?.sign}`}
              alt=""
            />
            <p>
              <b>( {data.answer.frequencyId === 1 ? data?.user?.name : "_______"} )</b>
            </p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              Teknisi PM 6 Bln
            </p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              <i>Tech. PM 6 Month</i>
            </p>
          </>
          <>
            <p>Diperiksa Oleh :</p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              <i>Checked By:</i>
            </p>

            <img
              style={{
                width: "8mm",
                height: "8mm",
              }}
              src={data.answer.frequencyId === 1 && `${process.env.REACT_APP_API_URL}${data.approval[0].user?.sign}`}
              alt=""
            />
            <p>
              <b>({data.answer.frequencyId === 1 ? data.approval[0]?.user?.name : "_______"})</b>
            </p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              {data.approval[0].approval_detail.user.name}
            </p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              <i>{data.approval[0].approval_detail.user.name}</i>
            </p>
          </>
          <>
            <p>Diketahui Oleh :</p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              <i>Acknowledge By:</i>
            </p>

            <img
              style={{
                width: "8mm",
                height: "8mm",
              }}
              src={data.answer.frequencyId === 1 && `${process.env.REACT_APP_API_URL}${data.approval[1].user?.sign}`}
              alt=""
            />
            <p>
              <b>({data.answer.frequencyId === 1 ? data.approval[1]?.user?.name : "_______"})</b>
            </p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              {data.approval[1].approval_detail.user.name}
            </p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              <i>{data.approval[1].approval_detail.user.name}</i>
            </p>
          </>
        </div>
        <div
          style={{
            width: "12.5mm",
            textAlign: "center",
            border: "0.1px solid black",
          }}
        >
          <p>Tanggal / Date :</p>
          <p
            style={{
              marginTop: -4,
            }}
          >
            {data.answer.frequencyId === 2 ? new Date(data?.answer?.createdAt * 1000).toLocaleString().split(",")[0] : "-"}
          </p>
          <>
            <p>Pemeriksaan Oleh :</p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              <i>Inspection By:</i>
            </p>

            <img
              style={{
                width: "8mm",
                height: "8mm",
              }}
              src={data.answer.frequencyId === 2 && `${process.env.REACT_APP_API_URL}${data.user?.sign}`}
              alt=""
            />
            <p>
              <b>( {data.answer.frequencyId === 2 ? data?.user?.name : "_______"} )</b>
            </p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              Teknisi PM 1 Thn
            </p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              <i>Tech. PM 1 Year</i>
            </p>
          </>
          <>
            <p>Diperiksa Oleh :</p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              <i>Checked By:</i>
            </p>
            <img
              style={{
                width: "8mm",
                height: "8mm",
              }}
              src={data.answer.frequencyId === 2 && `${process.env.REACT_APP_API_URL}${data.approval[0].user?.sign}`}
              alt=""
            />
            <p>
              <b>({data.answer.frequencyId === 2 ? data.approval[0]?.user?.name : "_______"})</b>
            </p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              {data.approval[0].approval_detail.user.name}
            </p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              <i>{data.approval[0].approval_detail.user.name}</i>
            </p>
          </>
          <>
            <p>Diketahui Oleh :</p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              <i>Acknowledge By:</i>
            </p>
            <img
              style={{
                width: "8mm",
                height: "8mm",
              }}
              src={data.answer.frequencyId === 2 && `${process.env.REACT_APP_API_URL}${data.approval[1].user?.sign}`}
              alt=""
            />
            <p>
              <b>({data.answer.frequencyId === 2 ? data.approval[1]?.user?.name : "_______"})</b>
            </p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              {data.approval[1].approval_detail.user.name}
            </p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              <i>{data.approval[1].approval_detail.user.name}</i>
            </p>
          </>
        </div>
        <div
          style={{
            width: "12.5mm",
            textAlign: "center",
            border: "0.1px solid black",
          }}
        >
          <p>Tanggal / Date :</p>
          <p
            style={{
              marginTop: -4,
            }}
          >
            {"-"}
          </p>
          <>
            <p>Pemeriksaan Oleh :</p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              <i>Inspection By:</i>
            </p>

            <img
              style={{
                width: "8mm",
                height: "8mm",
              }}
              src={""}
              alt=""
            />
            <p>
              <b>( {"_______"} )</b>
            </p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              Teknisi PM 6 Bln
            </p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              <i>Tech. PM 6 Month</i>
            </p>
          </>
          <>
            <p>Diperiksa Oleh :</p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              <i>Checked By:</i>
            </p>
            <img
              style={{
                width: "8mm",
                height: "8mm",
              }}
              src={""}
              alt=""
            />
            <p>
              <b>({"_______"})</b>
            </p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              {data.approval[0].approval_detail.user.name}
            </p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              <i>{data.approval[0].approval_detail.user.name}</i>
            </p>
          </>
          <>
            <p>Diketahui Oleh :</p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              <i>Acknowledge By:</i>
            </p>
            <img
              style={{
                width: "8mm",
                height: "8mm",
              }}
              src={""}
              alt=""
            />
            <p>
              <b>({"_______"})</b>
            </p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              {data.approval[1].approval_detail.user.name}
            </p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              <i>{data.approval[1].approval_detail.user.name}</i>
            </p>
          </>
        </div>
        <div
          style={{
            width: "12.5mm",
            textAlign: "center",
            border: "0.1px solid black",
          }}
        >
          <p>Tanggal / Date :</p>
          <p
            style={{
              marginTop: -4,
            }}
          >
            {data.answer.frequencyId === 3 ? new Date(data?.answer?.createdAt * 1000).toLocaleString().split(",")[0] : "-"}
          </p>
          <>
            <p>Pemeriksaan Oleh :</p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              <i>Inspection By:</i>
            </p>

            <img
              style={{
                width: "8mm",
                height: "8mm",
              }}
              src={data.answer.frequencyId === 3 && `${process.env.REACT_APP_API_URL}${data.user?.sign}`}
              alt=""
            />
            <p>
              <b>( {data.answer.frequencyId === 3 ? data?.user?.name : "_______"} )</b>
            </p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              Teknisi PM 2 Thn
            </p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              <i>Tech. PM 2 Year</i>
            </p>
          </>
          <>
            <p>Diperiksa Oleh :</p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              <i>Checked By:</i>
            </p>
            <img
              style={{
                width: "8mm",
                height: "8mm",
              }}
              src={data.answer.frequencyId === 3 && `${process.env.REACT_APP_API_URL}${data.approval[0].user?.sign}`}
              alt=""
            />
            <p>
              <b>({data.answer.frequencyId === 3 ? data.approval[0]?.user?.name : "_______"})</b>
            </p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              {data.approval[0].approval_detail.user.name}
            </p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              <i>{data.approval[0].approval_detail.user.name}</i>
            </p>
          </>
          <>
            <p>Diketahui Oleh :</p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              <i>Acknowledge By:</i>
            </p>
            <img
              style={{
                width: "8mm",
                height: "8mm",
              }}
              src={data.answer.frequencyId === 3 && `${process.env.REACT_APP_API_URL}${data.approval[1].user?.sign}`}
              alt=""
            />
            <p>
              <b>({data.answer.frequencyId === 3 ? data.approval[1]?.user?.name : "_______"})</b>
            </p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              {data.approval[1].approval_detail.user.name}
            </p>
            <p
              style={{
                marginTop: -5,
              }}
            >
              <i>{data.approval[1].approval_detail.user.name}</i>
            </p>
          </>
        </div>
      </div>
    </div>
  );

  const exportPDF = () => {
    doc.html(renderToString(pdf), {
      callback: function (doc) {
        doc.save(`${data?.form?.name} - ${data?.tag_number?.tagNumber}.pdf`);
      },
      x: 10,
      y: 10,
      margin: 1,
      autoPaging: "text",
    });
  };
  return (
    <Paper
      xs={12}
      sx={{
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        padding: 2,
        borderRadius: 2,
        marginBottom: 2,
      }}
    >
      <Grid container spacing={2} justifyContent={"space-between"}>
        <Grid item xs={12} md={4} justifyContent={"start"}>
          <Typography
            variant={"h5"}
            sx={{
              marginBottom: 2,
            }}
            align={"left"}
          >
            {data?.form?.name}
          </Typography>
          <Typography variant={"body1"} align={"left"}>
            Tag Number
          </Typography>
          <Typography
            variant={"body1"}
            sx={{
              fontWeight: "bold",
              marginBottom: 2,
            }}
            align={"left"}
          >
            {data?.tag_number?.tagNumber}
          </Typography>
          <Typography variant={"body1"} align={"left"}>
            Description
          </Typography>
          <Typography
            variant={"body1"}
            sx={{
              fontWeight: "bold",
              marginBottom: 2,
            }}
            align={"left"}
          >
            {data?.pm_schedule?.description}
          </Typography>

          <Typography variant={"body1"} align={"left"}>
            Category
          </Typography>
          <Typography
            variant={"body1"}
            sx={{
              fontWeight: "bold",
              marginBottom: 2,
            }}
            align={"left"}
          >
            {data?.category?.name}
          </Typography>

          <Typography variant={"body1"} align={"left"}>
            Filled At
          </Typography>
          <Typography
            variant={"body1"}
            sx={{
              fontWeight: "bold",
              marginBottom: 2,
            }}
            align={"left"}
          >
            {data.answer.createdAt ? new Date(data.answer.createdAt * 1000).toLocaleDateString() : ""}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {data?.approval
              .sort((a, b) => a.approval_detail.order - b.approval_detail.order)
              .map((form, index) => (
                <Step key={form.id}>
                  <StepLabel
                    optional={
                      <>
                        <Typography>
                          {t("component.text.verifiedBy")} {form?.approval_detail?.user.name}
                        </Typography>
                        {form?.isApproved === 0 ? <Typography>{t("component.text.process")}</Typography> : <Typography>{t("component.text.done")}</Typography>}
                      </>
                    }
                  >
                    {form.name}
                  </StepLabel>
                  <StepContent>
                    {form.dcaFormSchedule?.status === 0 && form.dcaFormSchedule?.date <= Math.floor(new Date().getTime() / 1000) && (
                      <Link to={`/dca-audit/form/${form.id}`}>
                        <Button variant="contained">Open</Button>
                      </Link>
                    )}
                  </StepContent>
                </Step>
              ))}
          </Stepper>
        </Grid>
        <Grid item xs={12} md={2} justifyContent={"start"}>
          <Box sx={{ textAlign: "right" }}>
            <Link to={`/submission-status/view/${id}`}>
              <Button sx={{ marginRight: 1 }} variant="contained" color="primary">
                {t("component.button.detail")}
              </Button>
            </Link>
            {!data?.approval?.find((approval) => approval.isApproved === 0) && (
              <Button variant="contained" color="secondary" onClick={exportPDF}>
                Export PDF
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}
