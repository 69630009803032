import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

export default function CreateModal({ open, onClose, data, index, setData }) {
  const { t } = useTranslation();

  const [activitiesEn, setActivitiesEn] = useState(data?.activitiesEn || "");
  const [activitiesId, setActivitiesId] = useState(data?.activitiesId || "");

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "activitiesEn":
        setActivitiesEn(value);
        break;
      case "activitiesId":
        setActivitiesId(value);
        break;
      default:
        break;
    }
  }
    
    useEffect(() => {
      if(data) {
        setActivitiesEn(data.activitiesEn);
        setActivitiesId(data.activitiesId);
      } else {
        setActivitiesEn("");
        setActivitiesId("");
      }
    }, [data, index]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (activitiesEn === "" || activitiesId === "") {
      toast.error("Please fill all the fields");
      return;
    }
    setData((prev) => {
      const newData = [...prev];
      if(data) newData[index] = { ...data, activitiesEn, activitiesId };
      else newData.push({ activitiesEn, activitiesId });
      return newData;
    });
    setActivitiesEn("");
    setActivitiesId("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {data
          ? t("component.title.editActivity")
          : t("component.title.addActivity")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {data
            ? t("component.title.editActivityDesc")
            : t("component.title.addActivityDesc")}
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            margin="dense"
            name="activitiesEn"
            label={`${t("component.text.activity")} (EN)`}
            type="text"
            fullWidth
            value={activitiesEn}
            onChange={handleChange}
            required
          />
          <TextField
            autoFocus
            margin="dense"
            name="activitiesId"
            label={`${t("component.text.activity")} (ID)`}
            type="text"
            fullWidth
            value={activitiesId}
            onChange={handleChange}
            required
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("component.button.cancel")}
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          {data ? t("component.button.save") : t("component.button.add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}