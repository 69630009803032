import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";

import {
  Button,
  Grid,
  Container,
  Card,
  Avatar,
  Box,
  TextField,
  Divider,
  Typography,
} from "@mui/material";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Loading from "../components/Loading";

export default function Profile() {
  const { t } = useTranslation();
  const axios = useAxiosPrivate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);

  useEffect(() => {
    document.title = `${t("page.profile.profile")} - ${
      process.env.REACT_APP_NAME
    }`;
    // eslint-disable-next-line
  }, []);

  const getProfile = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`/user/detail`);
      setData((prev) => res.data.data);
      setFile((prev) => res.data.data.sign);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProfile();
    // eslint-disable-next-line
  }, []);

  const handleUpload = () => async (e) => {
    const { files } = e.target;

    const upload = await axios.post(
      "/media",
      {
        file: files[0],
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    const fileName = upload.data.data.url;
    setFile(fileName);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);

    const sendData = {
      name: data.get("name"),
      sign: file,
    };

    try {
      const res = await axios.put(`/user/detail`, sendData);
      if (res.status >= 200 && res.status < 300) {
        res.data.message.reverse().forEach((message) => {
          toast.success(message);
        });
        getProfile();
      } else {
        res.data.message.reverse().forEach((message) => {
          toast.error(message);
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const changePassword = async (e) => {
    e.preventDefault();

    const data = new FormData(e.target);

    const sendData = {
      oldPassword: data.get("oldPassword"),
      newPassword: data.get("newPassword"),
      confirmPassword: data.get("confirmPassword"),
    };

    try {
      const res = await axios.put(`/user/password`, sendData);
      if (res.status >= 200 && res.status < 300) {
        res.data.message.reverse().forEach((message) => {
          toast.success(message);
        });
        getProfile();
      } else {
        res.data.message.reverse().forEach((message) => {
          toast.error(message);
        });
      }
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
  };

  if (loading) return <Loading />;

  return (
    <Container sx={{ mt: 4 }}>
      <Grid container spacing={4}>
        {/* <Grid item xs={12}>
          <Typography variant="h4">
            {t('page.profile.profile')}
          </Typography>
        </Grid> */}
        <Grid item xs={12}>
          <Card sx={{ p: 2, my: 5, mx: 30, overflow: "visible" }}>
            <Avatar
              alt={data.name}
              src="/static/images/avatar/2.jpg"
              sx={{ width: 100, height: 100, mx: "auto", mt: -8, zIndex: 10 }}
            />
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignContent="center"
            >
              <Grid item xs="auto"></Grid>
              <Grid item xs={12} md={9}>
                <Box component="form" onSubmit={onSubmit} sx={{ mt: 5, mb: 2 }}>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <TextField
                        sx={{ width: "100%" }}
                        label={t("component.text.name")}
                        name="name"
                        color="primary"
                        variant="outlined"
                        defaultValue={data.name}
                        autoComplete="name"
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        sx={{ width: "100%" }}
                        label="Email"
                        name="email"
                        color="primary"
                        variant="outlined"
                        value={data.email}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        sx={{ width: "100%" }}
                        label="Role"
                        color="primary"
                        variant="outlined"
                        value={data.roles
                          .map((role) => role.roleName)
                          .join(", ")}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>{t("component.text.signature")}</Typography>
                    </Grid>
                    <Grid xs={12} md={6}>
                      {file && (
                        <img
                          src={`${process.env.REACT_APP_API_URL}${file}?w=164&h=164&fit=crop&auto=format`}
                          alt="signature"
                          style={{ width: "100%" }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button variant="contained" component="label" fullWidth>
                        {t("component.button.upload")}
                        <input
                          type="file"
                          hidden
                          accept="image/*"
                          name="attachment"
                          onChange={handleUpload()}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="flex-end">
                    <Grid item xs={12} md={4}>
                      <Button
                        sx={{ width: "100%", mt: 2 }}
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        {t("component.button.update")}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                <Box
                  component="form"
                  onSubmit={changePassword}
                  sx={{ mt: 5, mb: 2 }}
                >
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <TextField
                        sx={{ width: "100%" }}
                        label={t("component.text.oldPassword")}
                        name="oldPassword"
                        type="password"
                        color="primary"
                        variant="outlined"
                        autoComplete="password"
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        sx={{ width: "100%" }}
                        label={t("component.text.newPassword")}
                        name="newPassword"
                        type="password"
                        color="primary"
                        variant="outlined"
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        sx={{ width: "100%" }}
                        label={t("component.text.confirmPassword")}
                        name="confirmPassword"
                        type="password"
                        color="primary"
                        variant="outlined"
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="flex-end">
                    <Grid item xs={12} md={6}>
                      <Button
                        sx={{ width: "100%", mt: 2 }}
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        {t("page.profile.changePassword")}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
