import { BottomNavigation, Grid, Typography } from "@mui/material";

export default function Footer() {
  return (
    // <Box display={{ xs: "none", md: "block" }}>
      <BottomNavigation
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          backgroundColor: "#fff",
          boxShadow: "0px -1px 5px 0px rgba(0,0,0,0.2)",
          zIndex: 1,
          alignItems: "center",
          paddingRight: '10px',
          paddingLeft: 'auto',
        }}
      >
        <Grid
          container
          justifyContent="end"
        >
          <Grid item display={{ xs: "none", md: "flex" }}>
            <Typography color="primary" variant="subtitle2">
              Copyright © {new Date().getFullYear()} Computerized Preventive Maintenance. All
              rights reserved.
            </Typography>
          </Grid>
        </Grid>
      </BottomNavigation>
  );
}
