import { default as axios, axiosPrivate } from "./axios";

const login = async (data) => {
  let response = null;
  try {
    const send = await axios.post('/auth/login', JSON.stringify(data), {
      headers: {
        "Content-Type": "application/json",
      },
    });
    response = send;
  } catch (err) {
    response = err.response;
  }

  return response;
}

const forgot = async (data) => {
  let response = null;
  try {
    const send = await axios.post('/auth/forgot-password', JSON.stringify(data), {
      headers: {
        "Content-Type": "application/json",
      },
    });
    response = send;
  } catch (err) {
    response = err.response;
  }

  return response;
}

const reset = async (data, token) => {
  let response = null;
  try {
    const send = await axios.put(`/auth/reset-password/${token}`, JSON.stringify(data), {
      headers: {
        "Content-Type": "application/json",
      },
    });
    response = send;
  } catch (err) {
    response = err.response;
  }

  return response;
}

const register = async (data) => {
  let response = null;
  try {
    const send = await axios.post('/auth/register', JSON.stringify(data), {
      headers: {
        "Content-Type": "application/json",
      },
    });
    response = send;
  } catch (err) {
    response = err.response;
  }

  return response;
}

const activate = async (data) => {
  let response = null;
  try {
    const send = await axios.put(`/auth/activate/${data}`, JSON.stringify([]), {
      headers: {
        "Content-Type": "application/json",
      },
    });
    response = send;
  } catch (err) {
    response = err.response;
  }

  return response;
}

const getRoles = async () => {
  let response = null;
  try {
    const send = await axiosPrivate.get('/auth/roles');
    response = send;
  } catch (err) {
    response = err.response;
  }

  return response;
}

const logout = async () => {
  let response = null;
  try {
    const send = await axiosPrivate.delete('/auth/logout', JSON.stringify([]));
    response = send;
  } catch (err) {
    response = err.response;
  }

  return response;
}

export { login, register, activate, logout, getRoles, forgot, reset };