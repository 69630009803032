import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import Cookies from "js-cookie";

import axios from "./services/axios";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyCGU9JVeluFDwkCOGeleWOiAIOkVVhiWi8",
  authDomain: "cpm-unioleo.firebaseapp.com",
  projectId: "cpm-unioleo",
  storageBucket: "cpm-unioleo.appspot.com",
  messagingSenderId: "3668955171",
  appId: "1:3668955171:web:a5410782484a7c51092196",
};

function requestPermission() {
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      // Initialize Firebase
      const app = initializeApp(firebaseConfig);

      // Initialize Firebase Cloud Messaging and get a reference to the service
      const messaging = getMessaging(app);
      const isLoggedIn = Cookies.get("isLoggedIn");
      if (isLoggedIn) {
        getToken(messaging, {
          vapidKey:
            "BH2cWGv8WjHYBXm2p3jjtUOJXKRngYXP0fqbcOUwTX6ZqCJcG6mHhcsIBlC24WYcotvEqrPqsYNYYW8UtNa5PsY",
        })
          .then((currentToken) => {
            if (currentToken) {
              // Send the token to your server and update the UI if necessary
              axios
                .post("/auth/fcm", { token: currentToken })
                .then((res) => {
                  // console.log(res);
                })
                .catch((err) => {
                  // console.log(err);
                });
            } else {
              // Show permission request UI
              // console.log('No registration token available. Request permission to generate one.');
              // ...
            }
          })
          .catch((err) => {
            // console.log('An error occurred while retrieving token. ', err);
            // ...
          });
      } else {
        // console.log('Unable to get permission to notify.');
      }
    }
  });
}
// if browser supports notifications
if ("Notification" in window) {
  requestPermission();
}

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage((payload) => {
//       resolve(payload);
//     });
//   });
