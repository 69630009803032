import {
  Box,
  Card,
  Container,
  Grid,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { register } from "../../services/auth";

import logo1 from "../../assets/img/logo1.png";
import Loading from "../../components/Loading";

export default function Register() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = `Register - ${process.env.REACT_APP_NAME}`;
    setLoading(false);
  }, [loading]);

  const onSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const sendData = {
      name: data.get("name"),
      email: data.get("email"),
      password: data.get("password"),
      confirm_password: data.get("confirm_password"),
    };

    try {
      const res = await register(sendData);
      if (res.status >= 200 && res.status < 300) {
        res.data.message.reverse().forEach((message) => {
          toast.success(message);
        });
        navigate("/auth/login");
      } else {
        res.data.message.reverse().forEach((message) => {
          toast.error(message);
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (loading) return <Loading />;

  return (
    <Container sx={{ width: { xs: "90%", md: "60%" } }}>
      <Box
        component="img"
        src={logo1}
        alt="logo"
        sx={{ width: { xs: "70%", lg: "40%" }, marginBottom: 5 }}
      />

      <Card>
        <Box
          component="form"
          sx={{ marginTop: 5, marginBottom: 5 }}
          onSubmit={onSubmit}
        >
          <Container>
            <Typography
              variant="h6"
              noWrap
              component="div"
              color="primary"
              sx={{ marginBottom: 5 }}
            >
              Register
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  sx={{ width: "100%" }}
                  label="Name"
                  name="name"
                  color="primary"
                  variant="outlined"
                  autoComplete="name"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  sx={{ width: "100%" }}
                  label="Email"
                  name="email"
                  color="primary"
                  variant="outlined"
                  autoComplete="email"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      sx={{ width: "100%" }}
                      label="Password"
                      name="password"
                      type="password"
                      color="primary"
                      variant="outlined"
                      autoComplete="current-password"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      sx={{ width: "100%" }}
                      label="Confirm Password"
                      name="confirm_password"
                      type="password"
                      color="primary"
                      variant="outlined"
                      autoComplete="current-password"
                      required
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary">
                  Register
                </Button>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  {"Already have an account?"}
                  <Link to="/auth/login"> Login</Link>
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Card>
    </Container>
  );
}
