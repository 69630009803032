import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import theme from "../../components/mui-theme";
import Loading from "../../components/Loading";

const cardStyle = {
  backgroundColor: theme.palette.primary.main,
  padding: 2,
  "&:hover": {
    top: "-40px",
    transform: "translateY(-10px)",
  },
  transition: "transform 0.3s",
};

export default function Menu() {
  const { t } = useTranslation();
  const [menuList, setMenuList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = `Menu - ${process.env.REACT_APP_NAME}`;

    const list = [
      {
        url: "/master-data/user-management",
        name: "User Management",
      },
      {
        url: "/master-data/menu-management",
        name: "Menu Management",
      },
      {
        url: "/master-data/roles/",
        name: "Role Management"
      },
      {
        url: "/master-data/email-template",
        name: "Email Templates"
      }
    ];

    setMenuList(list);
    setLoading(false);

    // eslint-disable-next-line
  }, []);

  if (loading) return <Loading />;

  return (
    <Container>
      <div style={{ marginBottom: 50 }}>
        <Typography variant="h3">
          {t("page.auth.menu.welcome")},{" "}
          <strong style={{ color: theme.palette.primary.main }}>
            {JSON.parse(localStorage.getItem("user")).name}
          </strong>
        </Typography>
      </div>
      <Grid container spacing={3} justifyContent="center">
        {menuList.map((item, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Link to={item.url}>
              <Card sx={cardStyle}>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      noWrap
                      component="div"
                      color="text.accent"
                    >
                      {item.name}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
