import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  TextField,
} from "@mui/material";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

export default function UpdateModal({ open, onClose, id }) {
  const axios = useAxiosPrivate();
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`/master/user/${id}`);

        const data = res.data.data;

        setName(data.name);
        setEmail(data.email);
        setIsActive(data.isActive === 1 ? true : false);
      } catch (err) {
        err.response.data.message.reverse().forEach((message) => {
          toast.error(message);
        });
      }
    };

    if (id) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "isActive":
        setIsActive((prev) => !prev);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { name, email, isActive };

    try {
      const res = await axios.put(`/master/user/${id}`, data);
      res.data.message.reverse().forEach((message) => {
        toast.success(message);
      });
      setName("");
      setEmail("");
      setIsActive(false);
      onClose();
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {t("component.title.editUser")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("component.title.editUserDesc")}
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label={t("component.text.name")}
            type="text"
            fullWidth
            value={name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="email"
            label={t("component.text.email")}
            type="text"
            fullWidth
            value={email}
            onChange={handleChange}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isActive}
                onChange={handleChange}
                name="isActive"
                color="primary"
              />
            }
            label={t("component.text.active")}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("component.button.cancel")}
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          {t("component.button.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
