import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function MonthYear({ month, setMonth, year, setYear }) {
  const { t } = useTranslation();

  const months = [
    { id: 0, name: t('month.1') },
    { id: 1, name: t('month.2') },
    { id: 2, name: t('month.3') },
    { id: 3, name: t('month.4') },
    { id: 4, name: t('month.5') },
    { id: 5, name: t('month.6') },
    { id: 6, name: t('month.7') },
    { id: 7, name: t('month.8') },
    { id: 8, name: t('month.9') },
    { id: 9, name: t('month.10') },
    { id: 10, name: t('month.11') },
    { id: 11, name: t('month.12') },
  ];

  const [years, setYears] = useState([]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear; i >= 2015; i--) {
      years.push(i);
    }
    setYears(years);
  }, []);

  return (
    <div className="month-year">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="month">{t('component.text.month')}</InputLabel>
            <Select
              labelId="month"
              id="month"
              label={t('component.text.month')}
              defaultValue=""
              fullWidth
              value={month}
              onChange={e => setMonth(e.target.value)}
            >
              {months && months.map((item, index) => (
                <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="year">{t('component.text.year')}</InputLabel>
            <Select
              labelId="year"
              id="year"
              label={t('component.text.year')}
              defaultValue=""
              fullWidth
              value={year}
              onChange={e => setYear(e.target.value)}
            >
              {years && years.map((item, index) => (
                <MenuItem key={index} value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  )
}