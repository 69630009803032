import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

export default function UpdateModal({ open, onClose, id, header, dataHeader, url }) {
  const axios = useAxiosPrivate();
  const { t } = useTranslation();

  // State for form fields
  const [pic, setPic] = useState("");
  const [tagNumber, setTagNumber] = useState("");
  const [headerFields, setHeaderFields] = useState(dataHeader.map(() => ""));

  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");

  const [selectedUser, setSelectedUser] = useState(null);

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [searchCategory, setSearchCategory] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`/mekanikal/${url}/${id}`);
        const data = res.data.data;
        setSelectedUser({ label: data.user.name, value: data.user.id });
        setSelectedCategory({ label: data.category.name, value: data.category.id });
        setPic(data.user.name);
        setTagNumber(data.tagNumber);
        setHeaderFields(dataHeader.map((item) => data[item]));
      } catch (err) {
        err.response.data?.message.reverse().forEach((message) => {
          toast.error(message);
        });
      }
    };

    if (id) {
      fetchData();
      fetchUser();
      fetchCategory();
    }
    // eslint-disable-next-line
  }, [id]);

  const fetchUser = async () => {
    const res = await axios.get(`/master/user${search ? `?search=${search}` : ""}`);
    setUsers(
      res.data.data.results.map((user) => ({
        label: user.name,
        value: user.id,
      }))
    );
  };

  const fetchCategory = async () => {
    const res = await axios.get(`/master/category${searchCategory ? `?search=${searchCategory}` : ""}`);
    setCategories(
      res.data.data.results.map((category) => ({
        label: category.name,
        value: category.id,
      }))
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      pic: selectedUser.value,
      tagNumber,
      ...dataHeader.reduce((acc, item, index) => {
        acc[item] = headerFields[index];
        return acc;
      }, {}),
      category: selectedCategory.value,
    };

    try {
      const res = await axios.put(`/mekanikal/${url}/${id}`, data);
      res.data.message.reverse().forEach((message) => {
        toast.success(message);
      });
      onClose();
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
  };

  const handleSearch = (val) => {
    setSearch((prev) => val);
    fetchUser();
  };

  const handleSelect = (val) => {
    setSelectedUser((prev) => val);
  };

  const handleSearchCategory = (val) => {
    setSearchCategory((prev) => val);
    fetchCategory();
  };

  const handleSelectCategory = (val) => {
    setSelectedCategory((prev) => val);
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t("component.title.editList")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("component.title.editListDesc")}</DialogContentText>
        <form onSubmit={handleSubmit}>
          <Autocomplete
            onChange={(event, value) => handleSelect(value)}
            onKeyUp={(event) => handleSearch(event.target.value)}
            id="combo-box-demo"
            value={selectedUser}
            options={users}
            sx={{ width: "100%", paddingTop: 2 }}
            renderInput={(params) => <TextField {...params} required label={t("component.text.pic")} />}
          />
          <TextField
            autoFocus
            margin="dense"
            name="tagNumber"
            label="Tag Number"
            type="text"
            fullWidth
            value={tagNumber}
            onChange={(e) => setTagNumber(e.target.value)}
          />
          <Autocomplete
            onChange={(event, value) => handleSelectCategory(value)}
            onKeyUp={(event) => handleSearchCategory(event.target.value)}
            id="combo-box-demo"
            value={selectedCategory}
            options={categories}
            sx={{ width: "100%", paddingTop: 1 }}
            renderInput={(params) => <TextField {...params} required label={t("component.text.category")} />}
          />
          {dataHeader.map((item, index) => (
            <TextField
              key={index}
              autoFocus
              margin="dense"
              name={item}
              label={header[index]}
              type="text"
              fullWidth
              value={headerFields[index]}
              onChange={(e) => {
                const updatedHeaderFields = [...headerFields];
                updatedHeaderFields[index] = e.target.value;
                setHeaderFields(updatedHeaderFields);
              }}
            />
          ))}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("component.button.cancel")}
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          {t("component.button.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
