import { Box,  Container, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import TabPanel from "../../../components/CustomTabPanel";
import RotatingEquipmentTable from "../../../components/master-list/mekanikal/RotatingEquipmentTable";
import theme from "../../../components/mui-theme";

export default function MasterMekanikal() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Container>
      <h1>Master List & PIC Plant Mekanikal</h1>
      <Box xs={12}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Rotating Equipment" />
        </Tabs>
      </Box>
      <TabPanel
        value={value}
        index={0}
        style={{ backgroundColor: theme.palette.primary.light }}
      >
        <RotatingEquipmentTable />
      </TabPanel>
    </Container>
  );
}
