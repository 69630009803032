import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";

import { Toaster } from "react-hot-toast";

import ProtectedRoute from "./components/ProtectedRoute";
import Header from "./components/Header";
import Footer from "./components/Footer";

import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import Activate from "./pages/auth/Activate";
import Menu from "./pages/auth/Menu";

import Notification from "./pages/Notification";
import Profile from "./pages/Profile";

import AdminDashboard from "./pages/admin/Dashboard";
import MenuManagement from "./pages/admin/MenuManagement";
import UserManagement from "./pages/admin/UserManagement";
import Roles from "./pages/admin/Roles";
import RoleDetail from "./pages/admin/RoleDetail";
import EmailTemplate from "./pages/admin/EmailTemplates";

import PlannedTask from "./pages/planned-task";
import PlannedTaskDetail from "./pages/planned-task/Detail";

import SubmissionStatus from "./pages/submission-status";
import SubmissionStatusDetail from "./pages/submission-status/Detail";
import SubmissionStatusView from "./pages/submission-status/View";

import EmployeeStatistic from "./pages/employee-statistic";

import SubmissionValidation from "./pages/submission-validation";
import SubmissionValidationDetail from "./pages/submission-validation/Detail";

import MasterListPICDashboard from "./pages/master-list-pic/Dashboard";
import MasterMekanikal from "./pages/master-list-pic/mekanikal";
import ImportMekanikalRotatingEquipment from "./pages/master-list-pic/mekanikal/rotating-equipment/Import";

import MasterIcae from "./pages/master-list-pic/icae";
import ImportIcae from "./pages/master-list-pic/icae/Import";

import PMSchedule from "./pages/pm-schedule";
import ImportPMSchedule from "./pages/pm-schedule/Import";

import MasterFormApproval from "./pages/master-form-approval";
import CreateForm from "./pages/master-form-approval/CreateForm";
import CreateApproval from "./pages/master-form-approval/CreateApproval";

const Error404 = React.lazy(() => import("./pages/404"));
const Error403 = React.lazy(() => import("./pages/403"));

const history = React.lazy(() => import("./history"));

const tabs = [
  {
    label: "Motor",
    url: "motor",
    header: ["Description", "Process Section", "Type"],
    data: ["description", "processSection", "type"],
  },
  {
    label: "Motor SLI",
    url: "motor-sli",
    header: ["Description", "Process Section", "Type"],
    data: ["description", "processSection", "type"],
  },
  {
    label: "Motor C2",
    url: "motor-c2",
    header: ["Description", "Process Section", "Type"],
    data: ["description", "processSection", "type"],
  },
  {
    label: "Motor Betaine",
    url: "motor-betaine",
    header: ["Description", "ISBL OSBL", "Type of Starter", "Merk", "Type", "Serial Number"],
    data: ["description", "isblOsbl", "typeOfStarter", "merk", "type", "serialNumber"],
  },
  {
    label: "Motor Pump Pilot Plant",
    url: "motor-pump-pilot-plant",
    header: ["Description", "ISBL OSBL", "Type of Starter", "Merk", "Type", "Serial Number"],
    data: ["description", "isblOsbl", "typeOfStarter", "merk", "type", "serialNumber"],
  },
  {
    label: "PSV",
    url: "psv",
    header: [
      "Description",
      "Type",
      "Process Section",
      "Service",
      "Location",
      "Elevation",
      "Merk",
      "Model Number",
      "I/O Type",
      "Alarm Display",
      "External Power Supply",
    ],
    data: ["description", "type", "processSection", "service", "location", "elevation", "merk", "modelNumber", "ioType", "alarmDisplay", "externalPowerSupply"],
  },
  {
    label: "PSV Betaine",
    url: "psv-betaine",
    header: ["Brand", "ISBL OSBL", "Model Number", "Type", "Location"],
    data: ["brand", "isblOsbl", "modelNumber", "type", "location"],
  },
  {
    label: "Instrument SLI",
    url: "instrument-sli",
    header: [
      "Description",
      "Type",
      "Process Section",
      "Service",
      "Location",
      "Elevation",
      "Merk",
      "Model Number",
      "I/O Type",
      "Alarm Display",
      "External Power Supply",
    ],
    data: ["description", "type", "processSection", "service", "location", "elevation", "merk", "modelNumber", "ioType", "alarmDisplay", "externalPowerSupply"],
  },
  {
    label: "Instrument PP",
    url: "instrument-pp",
    header: [
      "Description",
      "Type",
      "Process Section",
      "Service",
      "Location",
      "Elevation",
      "Merk",
      "Model Number",
      "I/O Type",
      "Alarm Display",
      "External Power Supply",
    ],
    data: ["description", "type", "processSection", "service", "location", "elevation", "merk", "modelNumber", "ioType", "alarmDisplay", "externalPowerSupply"],
  },
  {
    label: "Instrument",
    url: "instrument",
    header: [
      "Description",
      "Type",
      "Process Section",
      "Service",
      "Location",
      "Elevation",
      "Merk",
      "Model Number",
      "I/O Type",
      "Alarm Display",
      "External Power Supply",
    ],
    data: ["description", "type", "processSection", "service", "location", "elevation", "merk", "modelNumber", "ioType", "alarmDisplay", "externalPowerSupply"],
  },
  {
    label: "Instrument C2",
    url: "instrument-c2",
    header: ["Service", "PID", "Section", "Type", "Model"],
    data: ["service", "pid", "section", "type", "model"],
  },
  {
    label: "Instrument Betaine",
    url: "instrument-betaine",
    header: ["Brand", "ISBL OSBL", "Model Number Sensor", "Model Number Transmitter", "Type", "Location", "Instrument Range"],
    data: ["brand", "isblOsbl", "modelNumberSensor", "modelNumberTransmitter", "type", "location", "instrumentRange"],
  },
  {
    label: "WIT C2",
    url: "wit-c2",
    header: ["Service", "PID", "Type", "Model"],
    data: ["service", "pid", "type", "model"],
  },
  {
    label: "On Off Valve",
    url: "on-off-valve",
    header: [
      "Description",
      "Type",
      "Process Section",
      "Service",
      "Location",
      "Elevation",
      "Merk",
      "Model Number",
      "I/O Type",
      "Alarm Display",
      "External Power Supply",
    ],
    data: ["description", "type", "processSection", "service", "location", "elevation", "merk", "modelNumber", "ioType", "alarmDisplay", "externalPowerSupply"],
  },
  {
    label: "On Off Valve C2",
    url: "on-off-valve-c2",
    header: ["Service", "PID", "Model", "Type"],
    data: ["service", "pid", "model", "type"],
  },
  {
    label: "On Off Valve Betaine",
    url: "on-off-valve-betaine",
    header: [
      "Brand",
      "ISBL OSBL",
      "Model Number Valve Body",
      "Model Number Actuator",
      "Model Number Solenoid",
      "Model Number Switches",
      "Model Number Air Set",
      "Type",
      "Location",
    ],
    data: [
      "brand",
      "isblOsbl",
      "modelNumberValveBody",
      "modelNumberActuator",
      "modelNumberSolenoid",
      "modelNumberSwitches",
      "modelNumberAirSet",
      "type",
      "location",
    ],
  },
  {
    label: "On Off Valve SLI",
    url: "on-off-valve-sli",
    header: [
      "Description",
      "Type",
      "Process Section",
      "Service",
      "Location",
      "Elevation",
      "Merk",
      "Model Number",
      "I/O Type",
      "Alarm Display",
      "External Power Supply",
    ],
    data: ["description", "type", "processSection", "service", "location", "elevation", "merk", "modelNumber", "ioType", "alarmDisplay", "externalPowerSupply"],
  },
  {
    label: "Limit Switch",
    url: "limit-switch",
    header: [
      "Description",
      "Type",
      "Process Section",
      "Service",
      "Location",
      "Elevation",
      "Merk",
      "Model Number",
      "I/O Type",
      "Alarm Display",
      "External Power Supply",
    ],
    data: ["description", "type", "processSection", "service", "location", "elevation", "merk", "modelNumber", "ioType", "alarmDisplay", "externalPowerSupply"],
  },
  {
    label: "Limit Switch C2",
    url: "limit-switch-c2",
    header: ["Service", "PID", "Type", "Model"],
    data: ["service", "pid", "type", "model"],
  },
  {
    label: "Control Valve",
    url: "control-valve",
    header: [
      "Description",
      "Type",
      "Process Section",
      "Service",
      "Location",
      "Elevation",
      "Merk",
      "Model Number",
      "I/O Type",
      "Alarm Display",
      "External Power Supply",
    ],
    data: ["description", "type", "processSection", "service", "location", "elevation", "merk", "modelNumber", "ioType", "alarmDisplay", "externalPowerSupply"],
  },
  {
    label: "Control Valve C2",
    url: "control-valve-c2",
    header: ["Service", "PID", "Section", "Instrument Type", "Model", "Type"],
    data: ["service", "pid", "section", "instrumentType", "model", "type"],
  },
  {
    label: "Control Valve SLI",
    url: "control-valve-sli",
    header: [
      "Description",
      "Type",
      "Process Section",
      "Service",
      "Location",
      "Elevation",
      "Merk",
      "Model Number",
      "I/O Type",
      "Alarm Display",
      "External Power Supply",
    ],
    data: ["description", "type", "processSection", "service", "location", "elevation", "merk", "modelNumber", "ioType", "alarmDisplay", "externalPowerSupply"],
  },
  {
    label: "Control Valve Betaine",
    url: "control-valve-betaine",
    header: ["Brand", "ISBL OSBL", "Model Number Valve Body", "Model Number Actuator", "Model Number Positioner", "Type", "Location"],
    data: ["brand", "isblOsbl", "modelNumberValveBody", "modelNumberActuator", "modelNumberPositioner", "type", "location"],
  },
  {
    label: "CV & AV Pilot Plant",
    url: "cv-av-pilot-plant",
    header: [
      "Description",
      "Type",
      "Process Section",
      "Service",
      "Location",
      "Elevation",
      "Merk",
      "Model Number",
      "I/O Type",
      "Alarm Display",
      "External Power Supply",
    ],
    data: ["description", "type", "processSection", "service", "location", "elevation", "merk", "modelNumber", "ioType", "alarmDisplay", "externalPowerSupply"],
  },
  {
    label: "TOH",
    url: "toh",
    header: [
      "Description",
      "Type",
      "Process Section",
      "Service",
      "Location",
      "Elevation",
      "Merk",
      "Model Number",
      "I/O Type",
      "Alarm Display",
      "External Power Supply",
    ],
    data: ["description", "type", "processSection", "service", "location", "elevation", "merk", "modelNumber", "ioType", "alarmDisplay", "externalPowerSupply"],
  },
  {
    label: "Panel",
    url: "panel",
    header: ["Brand", "Location", "Type"],
    data: ["brand", "location", "type"],
  },
];

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Toaster
          toastOptions={{
            position: "top-right",
            style: {
              fontSize: "1rem",
            },
          }}
        />

        <React.Suspense fallback={<h1>Still Loading…</h1>}>
          <Routes history={history}>
            <Route
              path="/"
              element={
                <ProtectedRoute loginOnly={true}>
                  <Header>
                    <Menu />
                  </Header>
                </ProtectedRoute>
              }
            />

            {/* =============AUTH============= */}
            <Route
              path="/auth/login"
              element={
                <ProtectedRoute loginOnly={false}>
                  <Login />
                </ProtectedRoute>
              }
            />
            <Route
              path="/auth/register"
              element={
                <ProtectedRoute loginOnly={false}>
                  <Register />
                </ProtectedRoute>
              }
            />
            <Route
              path="/auth/forgot-password"
              element={
                <ProtectedRoute loginOnly={false}>
                  <ForgotPassword />
                </ProtectedRoute>
              }
            />
            <Route
              path="/auth/reset-password/:token"
              element={
                <ProtectedRoute loginOnly={false}>
                  <ResetPassword />
                </ProtectedRoute>
              }
            />
            <Route
              path="/auth/activate/:token"
              element={
                <ProtectedRoute loginOnly={false}>
                  <Activate />
                </ProtectedRoute>
              }
            />

            <Route
              path="/notification"
              element={
                <ProtectedRoute loginOnly={true}>
                  <Header noSidebar={true}>
                    <Notification />
                  </Header>
                </ProtectedRoute>
              }
            />

            <Route
              path="/profile"
              element={
                <ProtectedRoute loginOnly={true}>
                  <Header noSidebar={true}>
                    <Profile />
                  </Header>
                </ProtectedRoute>
              }
            />

            {/* =============PLANNED TASK============= */}
            <Route
              path="/planned-task"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <PlannedTask />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/planned-task/:id"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <PlannedTaskDetail />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />

            {/* =============SUBMISSION STATUS============= */}
            <Route
              path="/submission-status"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <SubmissionStatus />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/submission-status/view/:id"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <SubmissionStatusView />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/submission-status/:id"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <SubmissionStatusDetail />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />

            {/* =============EMPLOYEE STATISTIC============= */}
            <Route
              path="/employees-statistic"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <EmployeeStatistic />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />

            {/* =============SUBMISSION VALIDATION============= */}
            <Route
              path="/submission-validation"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <SubmissionValidation />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/submission-validation/:id"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <SubmissionValidationDetail />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />

            {/* =============MASTER LIST PIC============= */}
            <Route
              path="/master-list-pic"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <MasterListPICDashboard />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/master-list-pic/mekanikal"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <MasterMekanikal />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/master-list-pic/mekanikal/rotating-equipment/import"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <ImportMekanikalRotatingEquipment />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/master-list-pic/icae"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <MasterIcae />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            {tabs.map((tab, index) => (
              <Route
                key={index}
                path={`/master-list-pic/icae/${tab.url}/import`}
                element={
                  <ProtectedRoute loginOnly={true} protect={true}>
                    <Header>
                      <ImportIcae header={tab.header} url={tab.url} dataHeader={tab.data} label={tab.label} />
                    </Header>
                    <Footer />
                  </ProtectedRoute>
                }
              />
            ))}

            {/* =============PM SCHEDULE============= */}
            <Route
              path="/pm-schedule"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <PMSchedule />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/pm-schedule/import"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <ImportPMSchedule />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />

            {/* =============MASTER FORM APPROVAL============= */}
            <Route
              path="/master-form-approval"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <MasterFormApproval />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/master-form-approval/create-form"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <CreateForm />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/master-form-approval/edit-form/:id"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <CreateForm />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />

            <Route
              path="/master-form-approval/create-approval"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <CreateApproval />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/master-form-approval/edit-approval/:id"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <CreateApproval />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />

            {/* =============MASTER DATA============= */}
            <Route
              path="/master-data"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <AdminDashboard />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/master-data/user-management"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <UserManagement />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/master-data/menu-management"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <MenuManagement />
                  </Header>
                  <Footer />
                </ProtectedRoute>
              }
            />
            <Route path="/master-data/roles">
              <Route
                path="/master-data/roles/"
                element={
                  <ProtectedRoute loginOnly={true} protect={true}>
                    <Header>
                      <Roles />
                    </Header>
                    <Footer />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":id"
                element={
                  <ProtectedRoute loginOnly={true} protect={true}>
                    <Header>
                      <RoleDetail />
                    </Header>
                    <Footer />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route
              path="/master-data/email-template"
              element={
                <ProtectedRoute loginOnly={true} protect={true}>
                  <Header>
                    <EmailTemplate />
                  </Header>
                </ProtectedRoute>
              }
            />

            {/* =============ERROR============= */}
            <Route path="/error/404" element={<Error404 />} />
            <Route path="/error/403" element={<Error403 />} />
            <Route path="/*" element={<Error404 />} />
          </Routes>
        </React.Suspense>
      </header>
    </div>
  );
}

export default App;
