import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";

import ApproveForm from "../../components/ApproveForm";
import Appreciate from "../../components/Appreciate";
import { toast } from "react-hot-toast";

export default function SubmissionValidation() {
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [openAppreciate, setOpenAppreciate] = useState(false);

  const getData = async () => {
    setLoading(true);
    const res = await axios.get(`/submission-validation/${id}`);
    setData(res.data.data);
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.put(`/submission-validation/${id}`);
      res.data.message.reverse().forEach((message) => {
        toast.success(message);
      });
      setOpen(false);
      setOpenAppreciate(true);
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
      setOpen(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAppreciate = () => {
    setOpenAppreciate(false);
    navigate("/submission-validation");
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <ApproveForm
        handleSubmit={handleSubmit}
        open={open}
        onClose={handleClose}
        loading={loading}
      />
      <Appreciate open={openAppreciate} onClose={handleCloseAppreciate} />

      <Paper
        xs={12}
        sx={{
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          padding: 2,
          borderRadius: 2,
          marginBottom: 2,
        }}
      >
        <Typography variant="h4">{data?.form?.name}</Typography>
        <Typography>{data.tag_number.tagNumber}</Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              autoFocus
              margin="dense"
              name="tagNumber"
              label="No. Equipment"
              type="text"
              fullWidth
              value={data.tag_number.tagNumber}
              // onChange={(e) => setOrder(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              autoFocus
              margin="dense"
              name="tagNumber"
              label={t("component.text.description")}
              type="text"
              fullWidth
              value={data.category.name}
              // onChange={(e) => setOrder(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              autoFocus
              margin="dense"
              name="tagNumber"
              label={t("component.text.area")}
              type="text"
              fullWidth
              value={data.answer.area}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              autoFocus
              margin="dense"
              name="supervisor"
              label={t("component.text.supervisor")}
              type="text"
              fullWidth
              value={data.user.name}
              // onChange={(e) => setArea(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              autoFocus
              margin="dense"
              name="frequency"
              label={t("component.text.frequency")}
              type="text"
              fullWidth
              value={data.answer.frequency.name}
              // onChange={(e) => setArea(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              autoFocus
              margin="dense"
              name="frequency"
              label={t("component.text.filledAt")}
              type="text"
              fullWidth
              value={
                data.answer.createdAt
                  ? new Date(data.answer.createdAt * 1000).toLocaleDateString()
                  : ""
              }
              // onChange={(e) => setArea(e.target.value)}
            />
          </Grid>
        </Grid>
      </Paper>
      {data.form.detail.map((item) => (
        <Paper
          key={item.id}
          xs={12}
          sx={{
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            padding: 2,
            borderRadius: 2,
            marginBottom: 2,
          }}
        >
          <Typography variant="h6" align="start">
            {localStorage.getItem("language") === "en"
              ? item.activitiesEn
              : item.activitiesId}
          </Typography>

          <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={12} md={2}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="answer"
                  value={
                    data.answer.detail.find(
                      (detail) => detail.formDetailId === item.id
                    )?.answer
                  }
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label={t("component.text.yes")}
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label={t("component.text.no")}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={10}>
              <TextField
                autoFocus
                margin="dense"
                name="comment"
                label={t("component.text.comment")}
                type="text"
                fullWidth
                value={
                  data.answer.detail.find(
                    (detail) => detail.formDetailId === item.id
                  )?.notes
                }
              />
            </Grid>
            <Grid item xs={12}>
              {data.answer.detail.find(
                (detail) => detail.formDetailId === item.id
              )?.image ? (
                <img
                  key={
                    data.answer.detail.find(
                      (detail) => detail.formDetailId === item.id
                    )?.image
                  }
                  src={`${process.env.REACT_APP_API_URL}${
                    data.answer.detail.find(
                      (detail) => detail.formDetailId === item.id
                    )?.image
                  }?w=164&h=164&fit=crop&auto=format`}
                  srcSet={`${process.env.REACT_APP_API_URL}${
                    data.answer.detail.find(
                      (detail) => detail.formDetailId === item.id
                    )?.image
                  }?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt={
                    data.answer.detail.find(
                      (detail) => detail.formDetailId === item.id
                    )?.image
                  }
                  style={{
                    maxHeight: 300,
                  }}
                  loading="lazy"
                />
              ) : null}
            </Grid>
          </Grid>
        </Paper>
      ))}
      <Paper
        xs={12}
        sx={{
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          padding: 2,
          borderRadius: 2,
          marginBottom: 2,
        }}
      >
        <Typography variant="h6" align="start">
          {/* {localStorage.getItem("language") === "en"
            ? item.activitiesEn
            : item.activitiesId} */}
        </Typography>

        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              margin="dense"
              name="comment"
              label={t("component.text.notes")}
              type="text"
              fullWidth
              value={data.answer.notes}
            />
          </Grid>
          <Grid item xs={12}>
            {data.answer.attachment ? (
              <img
                key={data.answer.attachment}
                src={`${process.env.REACT_APP_API_URL}${data.answer.attachment}?w=164&h=164&fit=crop&auto=format`}
                srcSet={`${process.env.REACT_APP_API_URL}${data.answer.attachment}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                alt={data.answer.attachment}
                style={{
                  maxHeight: 300,
                }}
                loading="lazy"
              />
            ) : null}
          </Grid>
        </Grid>
      </Paper>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleOpen}
          >
            {t("component.button.approve")}
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Link to="/submission-validation">
            <Button variant="contained" color="background" fullWidth>
              {t("component.button.cancel")}
            </Button>
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}
