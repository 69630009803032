import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import MonthYear from "../../components/filters/MonthYear";

export default function SubmissionStatus() {
  const axios = useAxiosPrivate();
  const { t } = useTranslation();
  const [data, setData] = useState([]);

  const [month, setMonth] = useState(new Date().getMonth());
  const [year, setYear] = useState(new Date().getFullYear());
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [plant, setPlant] = useState("");

  const plants = [
    {
      label: "All Plants",
      value: "",
    },
    {
      label: "ICAE",
      value: "icae",
    },
    {
      label: "Mekanikal",
      value: "mekanikal",
    },
  ];

  const getData = async () => {
    setLoading(true);
    const res = await axios.get(`/submission-validation?search=${search}&month=${month}&year=${year}&type=${plant}`);
    setLoading(false);
    setData(res.data.data);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, year, search, plant]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div>
      <h1>Submission Validation</h1>
      <Grid container justifyContent={"center"}>
        <Grid item xs={12}>
          <TextField id="outlined-basic" label={t("component.text.search")} variant="outlined" fullWidth value={search} onChange={handleSearch} />
        </Grid>
        <Grid item xs={8}>
          <Box sx={{ my: 5 }}>
            <MonthYear month={month} setMonth={setMonth} year={year} setYear={setYear} />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ my: 5, ml: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="plant">Plant</InputLabel>
              <Select labelId="month" id="month" label="Plant" defaultValue="" fullWidth value={plant} onChange={(e) => setPlant(e.target.value)}>
                {plants &&
                  plants.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        {loading && <Typography>Loading...</Typography>}
        {data &&
          !loading &&
          data.map((item) => (
            <Grid item key={item.id} xs={10}>
              <Link to={"/submission-validation/" + item.id}>
                <Paper
                  xs={12}
                  sx={{
                    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    padding: 2,
                    margin: 1,
                    borderRadius: 2,
                  }}
                >
                  <Grid container spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item xs={12} sm={6}>
                      <Grid container spacing={2} justifyContent={"start"} alignItems={"center"}>
                        <Grid item xs={"auto"}>
                          {item?.approval?.find((approval) => approval.isApproved === 0) ? (
                            <Box
                              sx={{
                                bgcolor: "warning.main",
                                color: "primary.contrastText",
                                borderRadius: 1,
                                padding: 1,
                              }}
                            >
                              <FontAwesomeIcon icon="fa-solid fa-clipboard-list" />
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                bgcolor: "primary.main",
                                color: "primary.contrastText",
                                borderRadius: 1,
                                padding: 1,
                              }}
                            >
                              <FontAwesomeIcon icon="fa-solid fa-clipboard-check" />
                            </Box>
                          )}
                        </Grid>
                        <Grid item xs={8} sm={10}>
                          <Typography align="left">{item.form.name}</Typography>
                          <Typography
                            align="left"
                            sx={{
                              color: item?.approval?.length > 0 ? "primary.main" : "warning.main",
                            }}
                          >
                            {item.pm_schedule.description}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={"auto"}>
                      <Typography align="right">
                        {item?.approval?.find((approval) => approval.isApproved === 0)
                          ? t("component.text.beingReviewedBy") +
                            " " +
                            item?.approval?.filter((approval) => approval.isApproved === 0).sort((a, b) => a.approval_detail.order - b.approval_detail.order)[0]
                              .approval_detail.user.name
                          : t("component.text.accepted")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Link>
            </Grid>
          ))}
      </Grid>
      {/* <h4>{t('component.text.findTask')}</h4> */}
    </div>
  );
}
