import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import LinearProgressWithLabel from "../../../components/LinearProgressWithLabel";

import * as XLSX from "xlsx";

export default function Import({ label, url, header, dataHeader }) {
  const { t } = useTranslation();
  const axios = useAxiosPrivate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [total, setTotal] = useState(0);
  const [success, setSuccess] = useState(0);

  const handleFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);
      setData(
        data.map((item) => ({
          ...item,
          status: "Ready to Import",
          edit: false,
        }))
      );
    };
    reader.readAsBinaryString(file);
  };

  const handleSubmit = async () => {
    setLoadingUpload(true);
    setTotal(data.length);

    for (let index = 0; index < data.length; index++) {
      if ((index + 1) % 50 === 0)
        await new Promise((resolve) => setTimeout(resolve, 5000));

      const item = data[index];

      if (item.status !== "Success") {
        try {
          const sendData = {};

          dataHeader.forEach((head, index) => {
            sendData[head] = item[header[index]];
          });

          sendData.pic = item["PIC"];
          sendData.tagNumber = item["Tag Number"];

          const result = await axios.post(`/icae/${url}`, sendData);
          if (result.data.success) {
            setSuccess((prev) => prev + 1);

            setData((prev) => {
              const newData = [...prev];
              newData[index].status = "Success";
              return newData;
            });
          }
        } catch (err) {
          setData((prev) => {
            const newData = [...prev];
            newData[index].status = err.response.data?.message.join(", ");
            return newData;
          });
        }
      }
    }

    setLoadingUpload(false);
  };

  const handleDeleteRow = (index) => {
    setData((prev) => {
      const newData = [...prev];
      newData.splice(index, 1);
      return newData;
    });
  };

  const handleEditRow = (index) => {
    setData((prev) => {
      const newData = [...prev];
      newData[index].edit = true;
      return newData;
    });
  };

  return (
    <Container>
      <h4>Import Data {label} - Plant ICAE</h4>
      <Card>
        <TableContainer component={Paper}>
          <Grid container justifyContent={"end"} sx={{ padding: 2 }}>
            <Grid item></Grid>
            <Grid
              item
              sx={{
                width: "80%",
                marginTop: "10px",
              }}
            >
              <LinearProgressWithLabel
                progress={(success / (total || 1)) * 100}
                label={`${success} / ${total}`}
              />
            </Grid>
            <Grid item>
              <Button variant="contained" component="label" fullWidth>
                {t("component.button.upload")}
                <input
                  type="file"
                  hidden
                  accept=".xlsx, .xls"
                  onChange={(e) => handleFile(e)}
                />
              </Button>
            </Grid>
          </Grid>
          {/* <Box sx={{ overflow: "auto" }}> */}
          {/* <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}> */}
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="center">{t("component.text.pic")}</TableCell>
                <TableCell align="center">Tag Number</TableCell>
                {header.map((head, index) => (
                  <TableCell align="center" key={index}>
                    {head}
                  </TableCell>
                ))}
                <TableCell align="center">
                  {t("component.text.status")}
                </TableCell>
                <TableCell align="center">
                  {t("component.text.action")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && !data.length && (
                <TableRow>
                  <TableCell colSpan={3 + header.length} align="center">
                    {t("component.text.noData")}
                  </TableCell>
                </TableRow>
              )}
              {!loading &&
                data.length > 0 &&
                data.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.edit ? (
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={row["PIC"]}
                          onChange={(e) => {
                            const value = e.target.value;
                            setData((prev) => {
                              const newData = [...prev];
                              newData[index]["PIC"] = value;
                              return newData;
                            });
                          }}
                        />
                      ) : (
                        row["PIC"]
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {row.edit ? (
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={row["Tag Number"]}
                          onChange={(e) => {
                            const value = e.target.value;
                            setData((prev) => {
                              const newData = [...prev];
                              newData[index]["Tag Number"] = value;
                              return newData;
                            });
                          }}
                        />
                      ) : (
                        row["Tag Number"]
                      )}
                    </TableCell>
                    {header.map((head, index) => (
                      <TableCell align="center" key={index}>
                        {row.edit ? (
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={row[head]}
                            onChange={(e) => {
                              const value = e.target.value;
                              setData((prev) => {
                                const newData = [...prev];
                                newData[index][head] = value;
                                return newData;
                              });
                            }}
                          />
                        ) : (
                          row[head]
                        )}
                      </TableCell>
                    ))}
                    <TableCell align="center">{row.status}</TableCell>
                    <TableCell align="center">
                      {row.status !== "Success" && (
                        <Grid container spacing={1}>
                          <Grid item xs>
                            <Button
                              size="small"
                              variant="contained"
                              color="danger"
                              onClick={() => handleDeleteRow(index)}
                            >
                              {t("component.button.delete")}
                            </Button>
                          </Grid>
                          <Grid item xs>
                            {row.edit ? (
                              <Button
                                size="small"
                                variant="contained"
                                color="success"
                                onClick={() => {
                                  setData((prev) => {
                                    const newData = [...prev];
                                    newData[index].edit = false;
                                    return newData;
                                  });
                                }}
                              >
                                {t("component.button.save")}
                              </Button>
                            ) : (
                              <Button
                                size="small"
                                variant="contained"
                                color="warning"
                                onClick={() => handleEditRow(index)}
                              >
                                {t("component.button.edit")}
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {/* </Box> */}
          {/* </Box> */}
          {data.length > 0 && (
            <Grid container justifyContent={"end"} sx={{ padding: 2 }}>
              <Grid item>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  component="label"
                  fullWidth
                  disabled={loadingUpload}
                >
                  {t("component.button.submitAll")}
                </Button>
              </Grid>
            </Grid>
          )}
        </TableContainer>
      </Card>
    </Container>
  );
}
