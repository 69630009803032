import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import {
  Avatar,
  Badge,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Sidebar from "./Sidebar";

import logo_long from "../assets/img/logo_long.svg";
import logo1 from "../assets/img/logo1.png";

import { logout } from "../services/auth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useTranslation } from "react-i18next";

const settings = [
  {
    label: "Profile",
    id: "profile",
  },
  {
    label: "Sign Out",
    id: "sign-out",
  },
];

export default function Header({ children, noSidebar = false }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const axios = useAxiosPrivate();

  const [first, setFirst] = useState(0);

  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElNotif, setAnchorElNotif] = useState(null);

  const [notifications, setNotifications] = useState([]);
  const [totalNotif, setTotalNotif] = useState(0);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = async (e) => {
    const id = e.currentTarget.id;

    if (id === "profile") {
      navigate("/profile");
    }

    if (id === "sign-out") {
      const res = await logout();
      if (res.status >= 200 && res.status < 300) {
        res.data.message.reverse().forEach((message) => {
          toast.success(message);
        });
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/auth/login");
      } else {
        res.data.message.reverse().forEach((message) => {
          toast.error(message);
        });
      }
    }

    setAnchorElUser(null);
  };

  const onChange = (e) => {
    if (e.target.checked) {
      localStorage.setItem("language", "id");
    } else {
      localStorage.setItem("language", "en");
    }

    window.location.reload();
  };

  const getNotification = async () => {
    try {
      const res = await axios.get(`/user/notification`);
      if (res.data.unread > 0 && first === 0) {
        setFirst((prev) => prev + 1);
      }
      setTotalNotif((prev) => res.data.unread);
      setNotifications((prev) => res.data.data.results);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getNotification();
    const interval = setInterval(() => {
      getNotification();
    }, 1000 * 30);
    return () => clearInterval(interval);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    document.getElementsByClassName("App-header")[0].style.backgroundColor =
      "#FFFFFF";
  });

  const handleOpenNotif = (event) => {
    setAnchorElNotif(event.currentTarget);
  };

  const handleCloseNotif = () => {
    setAnchorElNotif(null);
  };

  const handleReadNotif = async (id, url) => {
    try {
      const res = await axios.put(`/user/notification/${id}`);
      if (res.status >= 200 && res.status < 300) {
        handleCloseNotif();
        navigate(url);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleReadAllNotif = async () => {
    try {
      const res = await axios.put(`/user/notification`);
      if (res.status >= 200 && res.status < 300) {
        getNotification();
        handleCloseNotif();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Box
        sx={{ display: "flex", marginBottom: "100px", width: "100%" }}
        color="pimary"
      >
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar>
            <Grid container justifyContent="space-between" alignItems="center">
              <Box component={Grid} item display={{ xs: "none", lg: "block" }}>
                <Link to="/">
                  <img src={logo_long} alt="logo" style={{ maxHeight: 40 }} />
                </Link>
              </Box>
              <Box component={Grid} item display={{ xs: "block", lg: "none" }}>
                <Link to="/">
                  <img src={logo1} alt="logo" style={{ maxHeight: 40 }} />
                </Link>
              </Box>
              <Grid item>
                <Grid container spacing={3} alignItems="center">
                  <Grid item>
                    <Stack direction="row" alignItems="center">
                      <Typography>EN</Typography>
                      <Switch
                        onChange={onChange}
                        color="default"
                        defaultChecked={
                          localStorage.getItem("language") === "id"
                        }
                      />
                      <Typography>ID</Typography>
                    </Stack>
                  </Grid>
                  <Grid item>
                    <Box sx={{ flexGrow: 0 }}>
                      <Tooltip title="Open notification">
                        <IconButton
                          id="notif"
                          color="background"
                          sx={{ p: 0 }}
                          onClick={handleOpenNotif}
                        >
                          <Badge
                            color="danger"
                            badgeContent={totalNotif}
                            max={999}
                          >
                            <FontAwesomeIcon icon={"fa-solid fa-bell"} />
                          </Badge>
                        </IconButton>
                      </Tooltip>
                      <Menu
                        dense="true"
                        sx={{ mt: "40px" }}
                        id="notif-appbar"
                        anchorEl={anchorElNotif}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={Boolean(anchorElNotif)}
                        onClose={handleCloseNotif}
                      >
                        {notifications.length < 1 && (
                          <MenuItem>
                            <Typography variant="body2" color="textSecondary">
                              {t("page.header.noNotification")}
                            </Typography>
                          </MenuItem>
                        )}
                        {notifications.map((notif, index) => (
                          <MenuItem
                            key={index}
                            onClick={(e) =>
                              handleReadNotif(notif.id, notif.url)
                            }
                            divider
                          >
                            <Grid container alignItems="center">
                              <Grid item xs={12}>
                                <Grid container justifyContent="space-between">
                                  <Grid item xs="auto">
                                    <Typography
                                      variant="body1"
                                      color={notif.isRead ? "" : "primary"}
                                    >
                                      {notif.isRead ? "" : "● "} {notif.title}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs="auto">
                                    <Typography
                                      variant="body2"
                                      align="right"
                                      color={notif.isRead ? "" : "primary"}
                                    >
                                      {new Date(
                                        notif.createdAt * 1000
                                      ).toLocaleString("id-ID")}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography
                                  variant="body2"
                                  color={notif.isRead ? "" : "primary"}
                                >
                                  {notif.body}
                                </Typography>
                              </Grid>
                            </Grid>
                          </MenuItem>
                        ))}
                        <MenuItem key="btn">
                          <Grid
                            container
                            justifyContent="space-between"
                            spacing={3}
                          >
                            <Grid item>
                              <Link to="/notification">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  onClick={handleCloseNotif}
                                >
                                  {t("page.header.seeAll")}
                                </Button>
                              </Link>
                            </Grid>
                            {notifications.length > 0 && (
                              <Grid item>
                                <Button
                                  variant="contained"
                                  color="warning"
                                  size="small"
                                  onClick={handleReadAllNotif}
                                >
                                  {t("page.header.markAllAsRead")}
                                </Button>
                              </Grid>
                            )}
                          </Grid>
                        </MenuItem>
                      </Menu>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box sx={{ flexGrow: 0 }}>
                      <Tooltip title="Open settings">
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                          <Avatar
                            alt={JSON.parse(localStorage.getItem("user")).name}
                            src="/static/images/avatar/2.jpg"
                          />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        sx={{ mt: "45px" }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                      >
                        {settings.map((setting) => (
                          <MenuItem
                            key={setting.id}
                            onClick={(e) => handleCloseUserMenu(e)}
                            id={setting.id}
                          >
                            <Typography textAlign="center">
                              {setting.label}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Menu>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        {!noSidebar && <Sidebar />}
        <Box component="main" sx={{ flexGrow: 1, p: 3, color: "#000000" }}>
          <Toolbar />
          {children}
        </Box>
      </Box>
    </>
  );
}
