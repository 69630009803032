import { Box, Card, Container, Grid, TextField, Button, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import toast from "react-hot-toast";

import loginImg from "../../assets/img/login.svg";
import { login } from "../../services/auth";

const loginImgStyle = {
  maxWidth: '80%',
  display: 'block',
  marginTop: '80px',
  marginBottom: 'auto',
  marginLeft: 'auto',
  marginRight: 'auto',
  position: 'relative',
}

export default function Login() {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Login - ${process.env.REACT_APP_NAME}`;
    document.getElementsByClassName('App-header')[0].style.backgroundColor = '#00540A';
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const sendData = {
      email: data.get("email"),
      password: data.get("password")
    }

    try {
      const res = await login(sendData);
      if (res.status >= 200 && res.status < 300) {
        res.data.message.reverse().forEach(message => {
          toast.success(message);
        });
        const { token, user } = res.data.data;

        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", token);
        window.location.reload();
        navigate("/");
      } else {
        res.data.message.reverse().forEach(message => {
          toast.error(message);
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Container>
      <Card>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <img src={loginImg} alt="login" className="img-fluid" style={loginImgStyle} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box component="form" onSubmit={onSubmit} sx={{ marginTop: 5, marginBottom: 5 }}>
              <Container>
                <Typography variant="h6" noWrap component="div" color="primary" sx={{ marginBottom: 5 }}>
                  Login
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField sx={{ width: '100%' }} label="Email" name="email" color="primary" variant="outlined" autoComplete="username" required />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField sx={{ width: '100%' }} label="Password" name="password" type="password" color="primary" variant="outlined" autoComplete="current-password" required />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Grid item xs={6} textAlign="left">
                        <Typography variant="body2">
                          {"Don't have an account?"}
                          <Link to="/auth/register"> Register</Link>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} textAlign="right">
                        <Typography variant="body2">
                          <Link to="/auth/forgot-password">Forgot Password?</Link>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                  </Grid>
                  <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary">Login</Button>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Grid>
        </Grid>

      </Card>
    </Container>
  )
}