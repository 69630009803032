import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import MuiDrawer from "@mui/material/Drawer";
import { Box, Divider, Toolbar, Typography, styled } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useAxiosPrivate from "../hooks/useAxiosPrivate";

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: open ? drawerWidth : 10,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidebar() {
  const axios = useAxiosPrivate();
  const location = useLocation();
  const [menu, setMenu] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(`/user/menu/`);
        setMenu(result.data.data);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [location]);

  if (loading) return null;
  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <Box display={open ? "flex" : "none"} justifyContent="space-between">
          <Typography variant="h6" sx={{ margin: 1 }}>
            {JSON.parse(localStorage.getItem("user")).name}
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            { <ChevronLeftIcon /> }
          </IconButton>
        </Box>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          // edge="center"
          sx={{
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Divider />
        <List>
          {menu
            .filter((menu) => menu.roleAccess[0].isRead)
            .map((sub, index) => (
              <Link to={sub.url} key={index}>
                <ListItem key={sub.id} disablePadding>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                    selected={sub.url === location.pathname}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 1 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <FontAwesomeIcon icon={sub.icon} />
                    </ListItemIcon>
                    <ListItemText
                      primary={sub.name}
                      sx={{ display: open ? 'block' : 'none' }}
                    />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
        </List>
      </Box>
    </Drawer>
  );
}
