import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { Card, Container, Grid } from "@mui/material";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import Loading from "../../components/Loading";
import UserTable from "../../components/admin/roles/UserTable";
import AccessTable from "../../components/admin/roles/AccessTable";

export default function RoleDetail() {
  const axios = useAxiosPrivate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [menu, setMenu] = useState({});

  const fetchData = async () => {
    try {
      const res = await axios.get(`/master/role/${id}`);
      setData(res.data.data);

      const resMenu = await axios.get(`/master/all-menu/${id}`);
      setMenu(resMenu.data.data);
      setLoading(false);
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }
  }

  useEffect(() => {
    document.title = `Role Detail - ${process.env.REACT_APP_NAME}`;
    if (id) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [id])

  const handleChange = async (value, menuId, access) => {
    try {
      let res;
      if (value) {
        res = await axios.post(`/master/assign-access/`, { menuId, access, roleId: id });
      } else {
        res = await axios.post(`/master/unassign-access/`, { menuId, access, roleId: id });
      }

      res.data.message.reverse().forEach(message => {
        toast.success(message);
      });

      fetchData();
    } catch (err) {
      err.response.data.message.reverse().forEach(message => {
        toast.error(message);
      });
    }

  }

  return loading ? (
    <Loading />
  ) : (
    <Container>
      <h1>Role Detail - {data.roleName}</h1>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card>
            <Container sx={{ marginBottom: 4 }}>
              <h3>{t('component.text.access')}</h3>
                <AccessTable menu={menu} access={data.roleAccess} onChange={handleChange} />
            </Container>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <Container sx={{ marginBottom: 4 }}>
              <h3>{t('component.text.user')}</h3>
              <UserTable users={data.users} id={id} />
            </Container>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )

}