import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import submit from "../assets/img/approve.svg";

export default function ApproveForm({ open, onClose, handleSubmit, loading }) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t("component.title.approveForm")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid
            container
            justifyContent="center"
            direction="column"
            alignItems="center"
          >
            <Grid item xs={12}>
              <img src={submit} alt="approve" style={{ maxHeight: "30vh" }} />
            </Grid>
            <Grid item xs={12}>
              {t("component.title.approveFormDesc")}
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("component.button.cancel")}</Button>
        <Button
          onClick={handleSubmit}
          autoFocus
          variant="contained"
          disabled={loading}
        >
          {t("component.button.approve")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
