import { Box, Container, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import TabPanel from "../../components/CustomTabPanel";
import TableForm from "../../components/master-form/Table";
import TableApproval from "../../components/master-approval/Table";
import theme from "../../components/mui-theme";

export default function MasterIcae() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue); 
  };
  return (
    <Container>
      <h1>Master Form & Approval</h1>
      <Box xs={12}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
            <Tab label="form" />
            <Tab label="approval" />
        </Tabs>
      </Box>
      
        <TabPanel
          value={value}
          index={0}
          style={{ backgroundColor: theme.palette.primary.light }}
        >
          <TableForm />
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          style={{ backgroundColor: theme.palette.primary.light }}
        >
          <TableApproval />
        </TabPanel>
    </Container>
  );
}
